import * as React from 'react';
import { useMemo } from 'react';

import ButtonLink from '@/components/display/buttons/button-link';
import EventSectionWrapper from '@/components/pages/event/layout/components/event-section-wrapper';
import SectionSpeakers from '@/components/pages/event/layout/components/section-speakers';
import useSpeakers from '@/components/pages/event/layout/hooks/use-speakers';
import { Event } from '@/types/article-type';
import { EventSpeaker } from '@/types/event-speaker';
import { SpeakersContent } from '@/types/events-custom-fields';

type Props = {
    speakers: EventSpeaker[];
    event: Event;
    speakersContent: SpeakersContent;
};
const SpeakersRender = ({ speakers, speakersContent, event }: Props) => {
    const { highlightedSpeaker, speakers: formattedSpeakers } = useSpeakers(event, speakers);

    // Map speakers array
    const highlightedSpeakers = useMemo(
        () =>
            formattedSpeakers?.filter(
                s =>
                    s.id &&
                    speakersContent.speakers_highlighted &&
                    speakersContent.speakers_highlighted?.includes(s.id),
            ) ?? [],
        [speakersContent],
    );

    return (
        <>
            {highlightedSpeakers && highlightedSpeakers.length > 0 && (
                <EventSectionWrapper>
                    <SectionSpeakers
                        title={speakersContent.speakers_highlighted_title ?? 'Highlighted Speakers'}
                        description={''}
                        className="bg-white"
                        eventSlug={event.slug}
                        speakers={highlightedSpeakers ?? []}
                        showAllSpeakersLink={false}
                    />
                </EventSectionWrapper>
            )}

            {speakers && speakersContent && (event?.customFields?.homepage_num_speakers ?? 0) > 0 && (
                <EventSectionWrapper
                    title={speakersContent?.nav_title || 'Speakers'}
                    description={speakersContent?.text_above}
                >
                    <SectionSpeakers
                        eventSlug={event.slug}
                        highlightedSpeaker={highlightedSpeaker}
                        speakers={formattedSpeakers}
                        displayCount={event.customFields.homepage_num_speakers}
                        showAllSpeakersLink
                        hideHeader
                    />
                </EventSectionWrapper>
            )}
            <div className="flex items-center mt-10">
                <ButtonLink
                    link={`/event/${event.slug}/speakers`}
                    className="mx-auto bg-event-accent text-white border border-transparent hover:border-event-accent hover:bg-white hover:text-event-accent"
                >
                    <span>View All {formattedSpeakers.length} Speakers</span>
                    <svg
                        width={16}
                        height={9}
                        viewBox="0 0 16 9"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 ml-2"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path d="M12.01 3.79688H0V5.79688H12.01V8.79688L16 4.79688L12.01 0.796875V3.79688Z" />
                    </svg>
                </ButtonLink>
            </div>
        </>
    );
};

export default SpeakersRender;
