import * as React from 'react';
import { Epg, Layout, type Program, useEpg } from 'planby';

import theme from '@/components/pages/event/layout/components/agenda/components/plany-by-theme';
import ProgramItemLayout from '@/components/pages/event/layout/components/agenda/components/program-item-layout';
import Timeline from '@/components/pages/event/layout/components/agenda/components/timeline';
import TrackItemLayout from '@/components/pages/event/layout/components/agenda/components/track-item-layout';
import { Track } from '@/types/event-agenda';
import timeStringToDate from '@/utils/functions/time/time-string-to-date';

type PlanByControllerProps = {
    date: string;
    tracks: Track[];
};

const PlanByController = (props: PlanByControllerProps) => {
    const [isLoading] = React.useState<boolean>(false);
    const { tracks, date } = props;

    // Theme adjustments
    theme.timeline.divider.bg = `var(--event-accent-color)`;

    const epg = tracks.flatMap(
        track =>
            track.agenda?.map(
                (trackTimeline): Program => ({
                    channelUuid: track.track_title || '',
                    id: `${trackTimeline.title}-${trackTimeline.time}-${trackTimeline.end_time}`,
                    title: trackTimeline.title || '',
                    description: trackTimeline.description || '',
                    image:
                        trackTimeline.speakers_list && trackTimeline.speakers_list.length > 0
                            ? trackTimeline.speakers_list[0]?.thumbnail || ''
                            : '',
                    speakers: trackTimeline.speakers_list,
                    since: timeStringToDate(`${date} ${trackTimeline.time}`).toISOString(),
                    till: timeStringToDate(`${date} ${trackTimeline.end_time}`).toISOString(),
                    bgColor: trackTimeline.track_tag_background_color,
                }),
            ) || [],
    );

    // const channelsData = React.useMemo(() => channels, [channels]);
    // const epgData = React.useMemo(() => epg, [epg]);
    const { getEpgProps, getLayoutProps } = useEpg({
        epg,
        channels:
            tracks?.map(a => ({
                uuid: a.track_title || '',
                logo: 'https://raw.githubusercontent.com/karolkozer/planby-demo-resources/master/resources/channel-logos/png/r-channel.png',
                title: a.track_title,
            })) || [],
        height: tracks.length * 400,
        dayWidth: 5000,
        sidebarWidth: 140,
        itemHeight: 320,
        isSidebar: true,
        isTimeline: true,
        isLine: true,
        startDate: epg[0]?.since.toString() || timeStringToDate(`${date} 09:00 am`).toISOString(),
        endDate: epg[epg.length - 1]?.till.toString() || timeStringToDate(`${date} 8:00 pm`).toISOString(),
        isBaseTimeFormat: true,
        globalStyles: `.planby {
    font-family: 'Soehne', system-ui, -apple-system,
}`,
        theme,
    });

    return (
        <div>
            <Epg isLoading={isLoading} {...getEpgProps()}>
                <Layout
                    {...getLayoutProps()}
                    renderTimeline={timelineProps => <Timeline {...timelineProps} />}
                    renderProgram={({ program, ...rest }) => (
                        <ProgramItemLayout key={program.data.id} program={program} {...rest} />
                    )}
                    renderChannel={({ channel }) => <TrackItemLayout key={channel.uuid} channel={channel} />}
                />
            </Epg>
        </div>
    );
};

export default PlanByController;
