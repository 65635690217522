import 'mapbox-gl/dist/mapbox-gl.css';

import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';

import { cn } from '@blockworks/ui/utils';

import HotelCards, { Hotel } from '@/components/pages/event/layout/components/hotel-booking/components/hotel-cards';

type FeaturedHotelProps = {
    hotel: Hotel;
    title?: string | null;
    className?: string;
    disableMapAnimation?: boolean;
};

const accessToken = process.env.MAPBOX_ACCESS_TOKEN || '';

const FeaturedHotel = (props: FeaturedHotelProps) => {
    const { title, hotel, className, disableMapAnimation } = props;

    const mapContainer = useRef(null);
    const map = useRef<mapboxgl.Map | null>(null);

    const centerMap = useCallback((center: LngLatLike) => {
        if (!map.current) return;
        if (!disableMapAnimation) {
            map.current.flyTo({
                center,
                speed: 3.0,
                essential: false,
            });
        } else {
            map.current?.setCenter(center);
        }
        new mapboxgl.Marker({ color: 'black', rotation: 45 }).setLngLat(center).addTo(map.current!);
    }, []);

    useEffect(() => {
        if (map.current) return;
        if (!mapContainer.current) return;

        map.current = new mapboxgl.Map({
            accessToken,
            // Blockworks office in new york is the default location
            center: [-73.989708, 40.74111],
            container: mapContainer.current,
            style: 'mapbox://styles/dennis-blockworks/clldr7gyc010n01qphq27gaa3',
            zoom: 13.3,
        });

        fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                hotel?.address,
            )}.json?access_token=${accessToken}&fuzzyMatch=false&limit=4`,
            {
                mode: 'cors',
            },
        )
            .then(response => {
                return response.json();
            })
            .then(geoLocation => {
                if (geoLocation.features?.length > 0 && map.current) {
                    let locationFound = false;
                    geoLocation.features.forEach((feature: any) => {
                        if (hotel?.address?.includes(feature.properties?.address)) {
                            centerMap(feature.center! as LngLatLike);
                            locationFound = true;
                        }
                    });

                    if (!locationFound) {
                        centerMap(geoLocation.features[0].center as LngLatLike);
                    }
                }
            });
    }, [mapContainer, centerMap, hotel.address]);

    return (
        <div className={cn('w-full', className)}>
            {title && <h2 className="text-xl font-semibold text-gray-900 mb-8">{title}</h2>}

            <div className="w-full flex flex-row gap-4">
                <HotelCards
                    hotels={[hotel]}
                    className="-mx-px grid grid-cols-1 border-t border-l border-gray-200 sm:mx-0 w-full sm:w-6/12 lg:w-4/12"
                />

                <div ref={mapContainer} className="map-container w-0 lg:w-8/12 h-[400px]" />
            </div>
        </div>
    );
};

export default FeaturedHotel;
