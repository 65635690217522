import React, { useEffect } from 'react';

import type { ComponentOverrides } from '../../utils/hooks/use-abstract-processor';
import { useHtmlProcessor } from '../../utils/hooks/use-html-processor';

type RenderHtmlProps<T extends ComponentOverrides> = {
    html: string;
    className?: string;
    rehypePlugins?: any[];
    components?: T;
    children?: React.ReactNode;
    onRendered?: (result: unknown) => void;
};

const RenderHtml = <T extends ComponentOverrides>(props: RenderHtmlProps<T>) => {
    const { html, className, rehypePlugins, children, onRendered, components } = props;
    const { content, isRendered } = useHtmlProcessor(html || '', components, rehypePlugins);

    useEffect(() => {
        if (isRendered && onRendered) {
            onRendered(content);
        }
    }, [isRendered, onRendered]);

    return (
        <div className={className}>
            <>
                {content}
                {children}
            </>
        </div>
    );
};

export { RenderHtml };
