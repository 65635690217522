import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocalStorage } from '@blockworks/platform/hooks';
import { format, isSameDay } from '@blockworks/platform/libs/date-fns';

import AgendaTabs, { TabOption } from '@/components/pages/event/layout/components/sessions/components/agenda-tabs';
import RoomSection from '@/components/pages/event/layout/components/sessions/components/room-section';
import SessionCard from '@/components/pages/event/layout/components/sessions/components/session-card';
import { Event } from '@/types/article-type';
import { EventSpeaker } from '@/types/event-speaker';
import { AgendaDay, Room, SessionFull, SessionSpeaker, SpeakerFull } from '@/types/pages/api/events/sessionize';

interface SessionizeAgendaProps {
    event: Event;
    agenda: AgendaDay[];
    speakers: EventSpeaker[];
    additionalSpeakers: SpeakerFull[];
}

const SessionizeAgendaLayout = ({ agenda, event, speakers = [], additionalSpeakers = [] }: SessionizeAgendaProps) => {
    const { storedValue, setValue } = useLocalStorage<{ sessions: string[] }>(`event.${event.id}`, {
        sessions: [],
    });
    const options = useMemo<TabOption[]>(() => {
        const defaultOptions: TabOption[] = [
            { type: 'favorites', value: 'favorites', hidden: storedValue.sessions.length === 0 },
        ];
        return defaultOptions.concat(
            agenda.map<TabOption>(({ date }) => ({ type: 'day', value: date, hidden: false })),
        );
    }, [agenda, storedValue]);

    const [tab, selectTab] = useState<TabOption>(() => {
        if (storedValue.sessions.length > 0) {
            return options[0]!;
        }
        const todaysAgenda = options.find(({ value }) => isSameDay(new Date(), new Date(value)))!;
        return todaysAgenda ?? options[0];
    });

    const speakersByName = useMemo<Record<string, SessionSpeaker>>(() => {
        let order = 0;
        const byName: Record<string, SessionSpeaker> = {};
        speakers?.forEach(speaker => {
            order += 1;
            const sessionSpeaker: SessionSpeaker = {
                name: speaker?.title?.trim()!,
                bio: speaker.description,
                thumbnail: speaker.thumbnail,
                taglinePrimary: speaker.custom_fields?.speaker_job?.value,
                taglineSecondary: speaker.custom_fields?.speaker_company?.value,
                order,
            };
            byName[sessionSpeaker.name] = sessionSpeaker;
        });

        additionalSpeakers?.forEach(speaker => {
            const name = speaker.fullName.replace(/\s\[Moderator\]|\s\./, '');
            const isModerator = name.includes('[Moderator]');

            if (byName[name]) {
                byName[name] = { ...byName[name], isModerator } as SessionSpeaker;
            } else {
                order += 1;
                const [taglinePrimary, taglineSecondary] = speaker.tagLine?.split?.(', ') ?? [];
                const sessionSpeaker = {
                    name,
                    bio: speaker.bio,
                    thumbnail: speaker.profilePicture,
                    taglinePrimary,
                    taglineSecondary,
                    order,
                    isModerator,
                };
                byName[name] = sessionSpeaker;
            }
        });
        return byName;
    }, [speakers, additionalSpeakers]);

    const getSessionSpeakers = useCallback(
        (sessionSpeakers: SessionFull['speakers']) => {
            return sessionSpeakers
                .map(({ name }) => {
                    const sanitized = name.replace(/\s\[Moderator\]|\s\./, '');

                    const speaker = speakersByName[sanitized]!;
                    const isModerator = name.includes('[Moderator]');
                    if (isModerator && speaker) {
                        return {
                            ...speaker,
                            isModerator: true,
                        };
                    }
                    return speaker;
                })
                .filter(Boolean)
                .sort((a, b) => (a.order > b.order ? 1 : -1));
        },
        [speakersByName],
    );

    const isSelected = useCallback((sessionId: string) => storedValue.sessions?.includes(sessionId), [storedValue]);

    const onSelectSession = useCallback(
        (sessionId: string) => {
            setValue(({ sessions: prev }) => {
                const next = prev.filter((sId: string) => sId !== sessionId);
                const sessions = next.length !== prev.length ? next : next.concat(sessionId);

                return {
                    sessions,
                };
            });
        },
        [setValue],
    );

    useEffect(() => {
        if (tab.type === 'favorites' && storedValue.sessions.length === 0 && options[1]) {
            selectTab(options[1]);
        }
    }, [tab, storedValue.sessions, options, selectTab]);

    const renderRoomSections = () => {
        let sections: Room[] = [];
        if (tab.type === 'day') {
            const activeDay = agenda.find(({ date }) => isSameDay(new Date(date), new Date(tab.value)));
            sections = activeDay?.rooms!;
        }
        if (tab.type === 'favorites') {
            sections = agenda.map(({ date, rooms }) => ({
                id: 0,
                name: format(new Date(date), 'MMMM dd'),
                sessions: rooms
                    .reduce<SessionFull[]>(
                        (acc, { sessions }) => acc.concat(sessions.filter(session => isSelected(session.id))),
                        [],
                    )
                    .sort((a, b) => {
                        const startDiff = new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime();
                        const endDiff = new Date(a.endsAt).getTime() - new Date(b.endsAt).getTime();
                        return startDiff === 0 ? endDiff : startDiff;
                    }),
                hasOnlyPlenumSessions: false,
            }));
        }
        return sections.map(room => (
            <RoomSection key={`${room.id}-${room.name}`} room={room}>
                {room.sessions.map(session => (
                    <SessionCard
                        key={session.id}
                        event={event}
                        session={session}
                        isSelected={isSelected(session.id)}
                        onSelectSession={onSelectSession}
                        getSpeakerList={getSessionSpeakers}
                        showRoom={tab.type === 'favorites'}
                    />
                ))}
            </RoomSection>
        ));
    };

    return (
        <div className="flex flex-col gap-4 p-5 md:p-10 xl:pl-20 md:pr-32 min-h-96">
            <div className="flex">
                <h2 className="flex-auto text-lg tracking-wide uppercase font-bold">Agenda</h2>
            </div>
            <AgendaTabs onSelect={selectTab} selected={tab} options={options} />
            <div className="grid lg:auto-cols-max lg:grid-flow-col overflow-x-scroll border max-w-full w-full lg:w-max">
                {renderRoomSections()}
            </div>
        </div>
    );
};

export default SessionizeAgendaLayout;
