import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import ButtonLink from '@/components/display/buttons/button-link';
import type ButtonProps from '@/components/display/buttons/type/button-props';

const SecondaryButton = (props: ButtonProps) => {
    return (
        <ButtonLink
            {...props}
            className={cn(
                'bg-gray-50 text-gray-900 border border-gray-100 hover:border-gray-200 hover:bg-white hover:text-black',
                props.className,
            )}
        >
            <>{props.children}</>
        </ButtonLink>
    );
};

export default SecondaryButton;
