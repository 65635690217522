import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

type EventHomepageSectionHeaderProps = {
    title: string | null;
    description?: string | null;
};
const EventHomepageSectionHeader = (props: EventHomepageSectionHeaderProps) => {
    const { title, description } = props;

    if (!title) {
        return null;
    }

    return (
        <div className="flex flex-col justify-center items-start relative gap-2 text-left w-full">
            <p className="text-2xl lg:text-3xl text-gray-900">{title}</p>
            {description && (
                <div className="w-full text-md lg:text-lg text-gray-500">
                    <RenderSimpleHtml html={description} />
                </div>
            )}
        </div>
    );
};

export default EventHomepageSectionHeader;
