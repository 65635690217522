import { useReducer } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { MapPinIcon } from '@heroicons/react/24/outline';

import { cn } from '@blockworks/ui/utils';

import { Room } from '@/types/pages/api/events/sessionize';

interface RoomSectionProps {
    initiallyOpen?: boolean;
    children?: JSX.Element | React.ReactNode;
    room: Room;
}

const RoomSection = ({ children, room, initiallyOpen = false }: RoomSectionProps) => {
    const [isOpen, toggle] = useReducer(state => !state, initiallyOpen);
    return (
        <div className="flex flex-col gap-8 w-full lg:max-w-[400px] lg:w-[400px] p-4 lg:p-8 even:bg-gray-100 ">
            <div className="flex items-center relative">
                <h3 className="flex-auto uppercase text-xxs font-bold tracking-wide">
                    <MapPinIcon className="w-4 h-4 mr-1 inline-block align-text-bottom" />
                    {room.name}
                </h3>
                <button
                    className="lg:hidden w-8 h-8 p-1 inline-flex items-center after:content-[''] after:inset-[-16px] after:absolute"
                    onClick={toggle}
                >
                    <ChevronRightIcon className={cn('transition-transform', isOpen ? 'rotate-90' : '')} />
                </button>
            </div>
            <div className={cn('flex-col gap-8', isOpen ? 'flex' : 'lg:flex hidden')}>{children}</div>
        </div>
    );
};

export default RoomSection;
