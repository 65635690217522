import * as React from 'react';
import Link from 'next/link';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { cn } from '@blockworks/ui/utils';

import EventSectionHeader from '@/components/pages/event/layout/components/event-section-header';
import { SponsorGroup } from '@/types/events-custom-fields';

type SectionSponsorsProps = {
    className?: string;
    eventSlug: string;
    sponsorGroup: SponsorGroup[];
    showAllLink: boolean;
};

const SectionSponsors = (props: SectionSponsorsProps) => {
    const { className, sponsorGroup, eventSlug, showAllLink } = props;

    const sponsorGroupConfig = [
        {
            className: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10',
            width: 375,
            height: 180,
        },
        {
            className: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8',
            width: 275,
            height: 170,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6',
            width: 215,
            height: 144,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
        {
            className: 'grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4',
            width: 175,
            height: 125,
        },
    ];

    return (
        <div className={cn('flex flex-col gap-10', className)}>
            {sponsorGroup.map((sponsor_group, sgIndex) => (
                <section key={sgIndex} className="flex flex-col items-center justify-center gap-3">
                    <EventSectionHeader
                        variant="plain"
                        title={sponsor_group.title || 'Sponsors'}
                        linkTitle={
                            showAllLink &&
                            sponsor_group.founding_sponsors &&
                            sponsor_group.founding_sponsors?.length > 16 ? (
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                        <Link
                                            href={`/event/${eventSlug}/sponsors${
                                                sponsor_group.title ? `?show=${sponsor_group.title}` : ''
                                            }`}
                                            className="flex-grow-0 flex-shrink-0 text-base text-left uppercase text-event-accent"
                                        >
                                            all{sponsor_group.title}
                                        </Link>
                                    </div>
                                    <svg
                                        width={16}
                                        height={9}
                                        viewBox="0 0 16 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 fill-event-accent"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path d="M12.01 3.79688H0V5.79688H12.01V8.79688L16 4.79688L12.01 0.796875V3.79688Z" />
                                    </svg>
                                </div>
                            ) : (
                                ''
                            )
                        }
                        linkUrl={`/event/${eventSlug}/sponsors${
                            sponsor_group.title ? `?show=${sponsor_group.title}` : ''
                        }`}
                    />
                    <div
                        className={cn(
                            'grid w-full',
                            sponsorGroupConfig[sgIndex]?.className ||
                                'grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10',
                        )}
                    >
                        {sponsor_group.founding_sponsors &&
                            sponsor_group.founding_sponsors.map((sponsor, sIndex) => (
                                <div
                                    key={sIndex}
                                    className="flex flex-col justify-center items-center align-center flex-grow gap-5 p-10 bg-white border overflow-hidden"
                                    style={{
                                        height: `${sponsorGroupConfig[sgIndex]?.height}px`,
                                    }}
                                >
                                    <ImageWithFallback
                                        src={sponsor.logo || ''}
                                        width={sponsorGroupConfig[sgIndex]?.width || 300}
                                        height={sponsorGroupConfig[sgIndex]?.height || 125}
                                        alt=""
                                        className="relative p-10 md:p-0 object-fit"
                                    />
                                </div>
                            ))}
                    </div>
                </section>
            ))}
        </div>
    );
};

export default SectionSponsors;
