import { format } from '@blockworks/platform/libs/date-fns';
import { cn } from '@blockworks/ui/utils';

export interface AgendaTabsProps {
    className?: string;
    options: TabOption[];
    selected: TabOption;
    onSelect: (value: TabOption) => void;
}

export interface TabOption {
    type: 'favorites' | 'day';
    value: string;
    hidden: boolean;
}

const AgendaTabs = ({ className, options, selected, onSelect }: AgendaTabsProps) => {
    return (
        <div className={cn('flex gap-4', className)}>
            {options.map(option => {
                const active = `text-event-accent font-bold`;
                const inactive = 'text-gray-500 hover:text-gray-700';
                let textLg = option.value;
                let textSm = option.value;

                if (option.hidden) return null;

                if (option.type === 'day') {
                    const Day = new Date(option.value);
                    textLg = format(Day, 'MMMM dd');
                    textSm = format(Day, 'MMM dd');
                }
                return (
                    <button
                        key={option.value}
                        className={cn(
                            'flex whitespace-nowrap py-3 px-0.5 text-xl lg:text-3xl capitalize',
                            selected.value === option.value ? active : inactive,
                            option.type === 'favorites' ? 'pr-5' : '',
                        )}
                        onClick={() => onSelect(option)}
                    >
                        <span className="lg:inline hidden">{textLg}</span>
                        <span className="lg:hidden">{textSm}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default AgendaTabs;
