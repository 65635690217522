import { Channel } from 'planby';

interface TrackItemLayoutProps {
    channel: Channel;
}

const TrackItemLayout = ({ channel }: TrackItemLayoutProps) => {
    const { position, title } = channel;
    return (
        <div className="whitespace-normal p-2.5 mb-4" style={{ height: `${position.height}px` }}>
            <p className="h-full cursor-default text-black font-bold p-6 bg-white border">{title}</p>
        </div>
    );
};

export default TrackItemLayout;
