const Menu = [
    {
        title: 'home',
        slug: 'home',
    },
    {
        title: 'agenda',
        slug: 'agenda',
    },
    {
        title: 'tickets',
        slug: 'tickets',
    },
    {
        title: 'book hotel',
        slug: 'book-hotel',
    },
    {
        title: 'speakers',
        slug: 'speakers',
    },
    {
        title: 'participating companies',
        slug: 'participating-companies',
    },
    {
        title: 'sponsors',
        slug: 'sponsors',
    },
    {
        title: 'FAQs',
        slug: 'faqs',
    },
    /* {
        title: 'media',
        slug: 'media',
    }, */
];

export default Menu;
