import * as React from 'react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

import GravityForm from '@/components/form/gravity/gravity-form';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';

const ContactUsLayout = (props: TabLayoutProps) => {
    const { event } = props;

    const content = event.customFields?.content?.filter(ev => ev.acf_fc_layout === 'form');
    const contactForm = content ? content[content.length - 1] : null;

    return (
        <div className="p-5 md:p-10 xl:px-20 md:pr-32 pb-96">
            <section>
                <div className="mb-12">
                    {contactForm?.text_above && <RenderSimpleHtml html={contactForm?.text_above!} />}
                </div>

                <GravityForm formId="79" />

                <div className="mt-12">
                    {contactForm?.text_below && <RenderSimpleHtml html={contactForm?.text_below!} />}
                </div>
            </section>
        </div>
    );
};

export default ContactUsLayout;
