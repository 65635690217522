import { createMutation } from 'react-query-kit';

import { wpApi } from './wp';

const submitBecomeASponsor = createMutation({
    mutationFn: wpApi.post.submitBecomeASponsor,
    mutationKey: [wpApi.post.submitBecomeASponsor.cachePrefix],
});

const wpMutations = {
    submitBecomeASponsor: { use: submitBecomeASponsor },
};

export { wpMutations };
