import * as React from 'react';
import { useCallback } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { cn } from '@blockworks/ui/utils';

import {
    NextButton,
    PrevButton,
    usePrevNextButtons,
} from '@/components/pages/event/layout/components/home/components/image-slider-arrow-buttons';
import { NextImage } from '@/types/next-image';

type ImageSliderProps = {
    imageUrls: NextImage[];
    className?: string;
    noAutoPlay?: boolean;
};

const ImageSlider = (props: ImageSliderProps) => {
    const { imageUrls, className, noAutoPlay } = props;
    const [viewportRef, emblaApi] = useEmblaCarousel({ loop: true }, [
        Autoplay({ playOnInit: !noAutoPlay, delay: 5000, stopOnInteraction: true }),
    ]);

    const onButtonClick = useCallback((_emblaApi: EmblaCarouselType) => {
        const { autoplay } = _emblaApi.plugins();
        if (!autoplay) return;
        if (autoplay.options.stopOnInteraction !== false) autoplay.stop();
    }, []);

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(
        emblaApi!,
        onButtonClick,
    );

    return (
        <div className={cn('relative w-full', className)}>
            <div className="overflow-hidden w-full" ref={viewportRef}>
                <div className="embla__container flex">
                    {imageUrls.map((image, index) => (
                        <div className="embla__slide flex" key={image.url}>
                            <ImageWithFallback
                                src={image.url}
                                alt={`Image ${index}`}
                                width={image.dimensions.width}
                                height={image.dimensions.height}
                                className="object-cover w-full max-h-[768px]"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="embla__buttons w-full px-5 flex items-center absolute bottom-5">
                <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                <div className="grow">&nbsp;</div>
                <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
            </div>
        </div>
    );
};

export default ImageSlider;
