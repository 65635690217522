import * as React from 'react';

import { slugify } from '@blockworks/platform/utils';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

import type { ThemeOrExperienceItem } from '@/types/events-custom-fields';

type Props = {
    items: ThemeOrExperienceItem[];
};
const EventExperiences = (props: Props) => {
    const { items } = props;

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 md:flex-row items-start gap-5 md:gap-10 w-full">
            {items.map((item: any) => (
                <div key={item.title} className="flex flex-col gap-6 p-5 md:p-10 bg-gray-900/[0.04] w-full md:w-auto">
                    <div key={slugify(item.title)} className="flex flex-col justify-center w-full lg:w-auto">
                        {item.image && (
                            <div className=" xl:min-w-[335px] pr-4 mb-5">
                                <ImageWithFallback
                                    width={335}
                                    height={185}
                                    src={item.image}
                                    alt={item.title}
                                    className="bg-gray-50 object-cover w-[335px] h-[185px]"
                                />
                            </div>
                        )}

                        <div className="text-lg lg:text-2xl text-left text-gray-900">{item.title}</div>
                        <div className="text-md md:text-lg lg:text-xl text-left text-gray-900/60">
                            <RenderSimpleHtml html={item.description} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EventExperiences;
