import * as React from 'react';

import SectionTickets from '@/components/pages/event/layout/components/section-tickets';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';
import type { PricingContent } from '@/types/events-custom-fields';

type TicketsLayoutProps = TabLayoutProps & {
    pricing: PricingContent;
};

const TicketsLayout = (props: TicketsLayoutProps) => {
    const { event, pricing } = props;

    return (
        <div className="p-5 py-10 md:p-10 xl:px-20 md:pr-32 xl:pr-32 pb-96">
            <SectionTickets eventSlug={event.slug} pricing={pricing} />
        </div>
    );
};

export default TicketsLayout;
