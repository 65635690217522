import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import type ButtonProps from '@/components/display/buttons/type/button-props';

const ButtonLink = (props: ButtonProps) => {
    const { children, size, link, className, target, rel, onClick } = props;

    let updatedRel = rel;
    if (!updatedRel && target === '_blank') {
        updatedRel = 'nofollow';
    }

    let sizeClassName = '';
    let paddingClassName = 'px-3 py-1.5';
    switch (size) {
        case 'tiny':
            sizeClassName = 'text-xs';
            paddingClassName = 'px-1 py-0.5';
            break;
        case 'small':
            sizeClassName = 'text-sm';
            break;
        case 'large':
            sizeClassName = 'text-lg';
            break;
        default:
            sizeClassName = 'text-base';
            break;
    }

    const Component = link ? 'a' : 'button';

    return (
        <Component
            href={link}
            target={target}
            rel={updatedRel}
            onClick={onClick}
            className={cn(
                'inline-flex items-center text-left cursor-pointer',
                className,
                sizeClassName,
                paddingClassName,
            )}
        >
            <>{children}</>
        </Component>
    );
};

export default ButtonLink;
