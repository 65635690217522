import * as React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';

type Props = {
    title: string | JSX.Element;
    url: string;
    isOpen: boolean;
    onClose: () => void;
    width?: number | string;
    height?: number;
};

const IframePopup = (props: Props) => {
    const { title, url, isOpen, onClose, width, height } = props;
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog open={isOpen} onClose={() => onClose()} className="relative z-50 h-screen w-full">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="p-12 w-full min-h-screen h-screen">
                        <Dialog.Panel
                            className="relative mx-auto rounded bg-white p-4 h-screen"
                            style={{ height: height ? `${height}pt` : '100%', width: width ? `${width}` : '100%' }}
                        >
                            <div className="absolute top-2 right-2 flex gap-1">
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                    className="inline-flex p-1.5 text-primary hover:bg-purple-50 focus:outline-none"
                                >
                                    <span className="sr-only">Open</span>
                                    <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true" />
                                </a>
                                <button
                                    type="button"
                                    onClick={() => onClose()}
                                    className="inline-flex p-1.5 text-primary hover:bg-purple-50 focus:outline-none"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <Dialog.Title className="border-b border-primary pb-1 mb-3">
                                <h3>{title}</h3>
                            </Dialog.Title>

                            <div className="pb-10 h-full">
                                <iframe
                                    src={url}
                                    className="border-0 w-full h-full p"
                                    height="100%"
                                    style={{ height: height ? `${height - 60}pt` : '100%' }}
                                />
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default IframePopup;
