import * as React from 'react';
import ReactCountdown from 'react-countdown';

import CountdownRenderer from '@/components/pages/event/layout/components/home/components/countdown-renderer';

type CountdownProps = {
    date: string | number | Date;
    text?: string;
    className?: string;
};

const Countdown = (props: CountdownProps) => {
    const { date, text, className } = props;

    return (
        <div className={className}>
            <div className="flex items-center gap-1 md:gap-2 lg:gap-3">
                <p className="text-md lg:text-lg uppercase">{text || 'Ticket prices increase in'}</p>
            </div>
            <ReactCountdown date={date} renderer={CountdownRenderer} />
        </div>
    );
};

export default Countdown;
