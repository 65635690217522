import * as React from 'react';

import { useTrack } from '@blockworks/platform/services/analytics';

const useAnalyticsTrigger = (eventName: string, parameters: any) => {
    /// Analytics start
    const track = useTrack();
    const trackingData = React.useMemo(() => parameters, [parameters]);

    return {
        trigger: () => {
            track(eventName, trackingData);
        },
    };
};

export default useAnalyticsTrigger;
