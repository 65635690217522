import * as React from 'react';

const BlockworksSvgLogo = () => {
    return (
        <svg
            width="240"
            height="40"
            viewBox="0 0 240 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-30 h-[20px] md:w-60 md:h-[40px] relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path d="M34.4402 0.12207H27.5V37.1097H34.4402V0.12207Z" fill="white" />
            <path
                d="M49.201 10.6572C41.5318 10.6572 35.75 16.3492 35.75 24.268C35.7796 26.9241 36.5943 29.5122 38.0917 31.7062C39.5891 33.9003 41.7022 35.6023 44.1649 36.5979C46.6276 37.5935 49.3298 37.8382 51.9313 37.3012C54.5328 36.7642 56.9173 35.4695 58.7845 33.5801C60.6516 31.6908 61.9181 29.2912 62.4243 26.6835C62.9306 24.0759 62.654 21.3768 61.6294 18.926C60.6048 16.4752 58.8779 14.3824 56.6664 12.911C54.4548 11.4396 51.8573 10.6554 49.201 10.6572ZM49.201 31.5776C45.8157 31.5776 42.6802 29.0712 42.6802 24.268C42.6802 19.4648 45.8157 16.9883 49.201 16.9883C52.5862 16.9883 55.7217 19.4448 55.7217 24.2879C55.7217 29.1311 52.5961 31.5776 49.201 31.5776Z"
                fill="white"
            />
            <path
                d="M76.7614 31.5177C73.2164 31.5177 70.1907 28.9114 70.1907 24.268C70.1907 19.6245 73.1864 17.0781 76.7114 17.0781C78.014 17.0235 79.2948 17.4257 80.3323 18.2152C81.3698 19.0047 82.0989 20.132 82.3934 21.402L88.6046 19.315C87.5062 14.9911 83.5418 10.6572 76.5517 10.6572C69.0922 10.6572 63.2505 16.3492 63.2505 24.268C63.2044 26.0599 63.5215 27.8427 64.1828 29.5088C64.844 31.175 65.8357 32.69 67.0981 33.9627C68.3605 35.2353 69.8675 36.2392 71.5282 36.914C73.1889 37.5887 74.9691 37.9203 76.7614 37.8887C83.5917 37.8887 87.606 33.5049 88.7444 29.2309L82.643 27.2338C82.287 28.5051 81.5129 29.6193 80.4458 30.3966C79.3786 31.1739 78.0806 31.5688 76.7614 31.5177Z"
                fill="white"
            />
            <path
                d="M145.732 11.446L140.989 27.9826L135.457 11.446H128.157L122.575 27.9227L117.822 11.446H114.576H110.522H105.499L96.6316 20.9925V0.12207H89.6914V37.1097H96.6316V30.3293L99.4975 27.3036L106.278 37.1097H114.776L104.291 22.2408L111.54 14.6315L118.71 37.1097H125.75L131.652 19.5845L137.644 37.1097H144.584L152.612 11.446H145.732Z"
                fill="white"
            />
            <path
                d="M164.607 10.6571C161.906 10.6234 159.255 11.3938 156.993 12.8703C154.731 14.3468 152.958 16.4627 151.902 18.9489C150.845 21.4352 150.551 24.1796 151.058 26.8331C151.566 29.4867 152.85 31.9295 154.749 33.851C156.648 35.7725 159.076 37.0859 161.723 37.6243C164.37 38.1626 167.118 37.9015 169.617 36.8742C172.115 35.847 174.252 34.0999 175.755 31.8551C177.258 29.6104 178.06 26.9693 178.058 24.2678C178.058 16.349 172.276 10.6571 164.607 10.6571ZM164.607 31.5774C161.212 31.5774 158.086 29.071 158.086 24.2678C158.086 19.4646 161.212 16.9681 164.607 16.9681C168.002 16.9681 171.128 19.4247 171.128 24.2678C171.128 29.1109 167.992 31.5774 164.607 31.5774Z"
                fill="white"
            />
            <path
                d="M186.098 15.2507V11.4461H179.367V37.0997H186.357V25.3663C186.357 19.8342 189.433 18.1166 192.978 18.1166C193.679 18.1149 194.378 18.1852 195.065 18.3263V11.3362C194.546 11.3362 194.016 11.2363 193.447 11.2363C191.2 11.2363 187.655 11.8555 186.098 15.2507Z"
                fill="white"
            />
            <path
                d="M220.998 11.446H211.921L203.053 20.9925V0.12207H196.113V37.1097H203.053V30.3293L205.919 27.3036L212.7 37.1097H221.198L210.723 22.2408L220.998 11.446Z"
                fill="white"
            />
            <path
                d="M232.36 21.5618L228.715 20.7729C227.307 20.5133 226.368 19.7743 226.368 18.4861C226.368 17.0182 227.826 15.9298 229.654 15.9298C230.666 15.8422 231.672 16.1517 232.461 16.7927C233.249 17.4337 233.757 18.3561 233.878 19.3649L239.669 18.0667C239.35 15.3006 236.903 10.6572 229.604 10.6572C224.071 10.6572 220.007 14.4618 220.007 19.0553C220.007 22.6602 222.244 25.626 227.197 26.7245L230.592 27.5034C232.589 27.9228 233.358 28.8615 233.358 30.0098C233.358 31.3679 232.26 32.5662 229.963 32.5662C226.967 32.5662 225.429 30.6889 225.27 28.6517L219.328 29.9699C219.638 32.8858 222.324 37.8987 230.013 37.8987C236.743 37.8987 239.999 33.6147 239.999 29.4506C239.979 25.676 237.422 22.6003 232.36 21.5618Z"
                fill="white"
            />
            <path opacity="0.3" d="M9.24691 18.5959H0V27.8429H9.24691V18.5959Z" fill="white" />
            <path opacity="0.2" d="M9.24691 27.8328H0V37.0797H9.24691V27.8328Z" fill="white" />
            <path opacity="0.8" d="M9.24691 0.102295H0V9.3492H9.24691V0.102295Z" fill="white" />
            <path opacity="0.5" d="M9.24691 9.34912H0V18.596H9.24691V9.34912Z" fill="white" />
            <path
                opacity="0.9"
                d="M17.0742 0.162043C16.7801 0.116942 16.4828 0.0969009 16.1854 0.10213H9.19531V9.33905H18.4322V0.53153C17.9898 0.373485 17.5357 0.249946 17.0742 0.162043Z"
                fill="white"
            />
            <path
                d="M24.7255 9.33887C24.7229 7.41069 24.1208 5.53104 23.0026 3.9602C21.8844 2.38935 20.3054 1.20504 18.4844 0.571289V9.37883L24.7255 9.33887Z"
                fill="white"
            />
            <path
                opacity="0.9"
                d="M24.7255 9.33887H18.4844V18.0665C20.299 17.435 21.8734 16.2568 22.9911 14.6939C24.1087 13.131 24.7146 11.2602 24.7255 9.33887Z"
                fill="white"
            />
            <path
                opacity="0.8"
                d="M9.24609 9.33887V18.5858H15.4373C16.4568 18.6106 17.4722 18.4481 18.4331 18.1065V9.37882L9.24609 9.33887Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M17.3151 18.5859C17.0355 18.5859 16.7459 18.5859 16.4663 18.5859H9.22656V27.8328H18.4635V18.7757C18.0852 18.6875 17.7016 18.6241 17.3151 18.5859Z"
                fill="white"
            />
            <path
                opacity="0.8"
                d="M18.4844 18.7756V27.8328H25.7541C25.7541 25.7211 25.0337 23.6727 23.7119 22.0259C22.3901 20.3791 20.546 19.2325 18.4844 18.7756Z"
                fill="white"
            />
            <path
                opacity="0.3"
                d="M9.24609 27.8328V37.0797H17.3147C17.7079 37.0424 18.0982 36.979 18.483 36.8899V27.8328H9.24609Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M18.4844 36.8899C20.546 36.4331 22.3901 35.2865 23.7119 33.6397C25.0337 31.9929 25.7541 29.9444 25.7541 27.8328H18.4844V36.8899Z"
                fill="white"
            />
        </svg>
    );
};

export default BlockworksSvgLogo;
