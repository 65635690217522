import * as React from 'react';

import { RenderHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import GravityForm from '@/components/form/gravity/gravity-form';

type DynamicPageProps = {
    content: string;
    className?: string;
};

const components = {
    gravityform: (formProps: any) => {
        if (!formProps?.formid) {
            return null;
        }

        return <GravityForm formId={formProps.formid} />;
    },
};

const DynamicPage = (props: DynamicPageProps) => {
    const { content, className } = props;

    if (!content) {
        return null;
    }

    return (
        <div className={cn('p-5 md:p-10 xl:px-20 sm:mr-16 mb-12', className)}>
            <section className="flex flex-col gap-10">
                <RenderHtml
                    className="prose prose-purple max-w-none prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-purple-500 prose-a:font-base prose-a:border-none prose-a:hover:border-none prose-a:underline prose-pre:rounded-xl prose-pre:bg-purple-900 prose-pre:shadow-lg"
                    html={content}
                    components={components}
                />
            </section>
        </div>
    );
};

export default DynamicPage;
