import * as React from 'react';

import { slugify } from '@blockworks/platform/utils';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';

import EventSectionWrapper from '@/components/pages/event/layout/components/event-section-wrapper';
import EventExperiences from '@/components/pages/event/layout/components/home/components/event-homepage-experience';
import EventHomepageNumbers from '@/components/pages/event/layout/components/home/components/event-homepage-numbers';
import ImageSlider from '@/components/pages/event/layout/components/home/components/image-slider';
import SpeakersRender from '@/components/pages/event/layout/components/home/components/renders/speakers-render';
import FeaturedHotel from '@/components/pages/event/layout/components/hotel-booking/components/featured-hotel';
import { Hotel } from '@/components/pages/event/layout/components/hotel-booking/components/hotel-cards';
import SectionParticipatingCompanies from '@/components/pages/event/layout/components/section-participating-companies';
import SectionSponsors from '@/components/pages/event/layout/components/section-sponsors';
import SectionTickets from '@/components/pages/event/layout/components/section-tickets';
import nextImageFromCustomFields from '@/components/pages/events/components/utils/next-image-from-custom-fields';
import { Event } from '@/types/article-type';
import { EventSpeaker, SpeakerInfo } from '@/types/event-speaker';
import {
    Content,
    ExperienceContent,
    HotelsContent,
    NumbersContent,
    ParticipatingCompaniesContent,
    PhotosContent,
    PricingContent,
    Quote,
    SpeakersContent,
    ThemesContent,
} from '@/types/events-custom-fields';
import { NextImage } from '@/types/next-image';

export const parseHotels = (hotelsContent: HotelsContent) => {
    try {
        return JSON.parse(hotelsContent?.hotels?.replace(/\n/g, '').replace(/\r/g, '').replace(/\t/g, ''));
    } catch (e) {
        /* empty */
    }

    return [];
};

export const EventSectionMap = [
    {
        key: 'experience',
        render: (experience: ExperienceContent) => (
            <>
                {experience && (
                    <EventSectionWrapper
                        title={experience.nav_title}
                        description={experience.text_above}
                        textBelow={experience.text_below}
                    >
                        <EventExperiences items={experience.experience_item} />
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
    {
        key: 'numbers',
        render: (numbers: NumbersContent) => (
            <>
                {numbers && (
                    <EventSectionWrapper title={numbers.title} description={numbers.description}>
                        <EventHomepageNumbers items={numbers.items} />
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
    {
        key: 'pricing',
        render: (pricing: PricingContent, event: Event) => (
            <>
                {pricing.price_cards.length > 0 && (
                    <EventSectionWrapper disableFadeIn>
                        <SectionTickets eventSlug={event.slug} pricing={pricing} />
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
    {
        key: 'speakers',
        render: (speakersContent: SpeakersContent, event: Event, passedSpeakers: SpeakerInfo[]) => (
            <SpeakersRender speakers={passedSpeakers} speakersContent={speakersContent} event={event} />
        ),
    },
    {
        key: 'hotels',
        render: (hotelsContent: HotelsContent) => {
            const hotels = parseHotels(hotelsContent);
            if (!hotels?.length) return null;

            const featuredHotel = hotels.filter((h: Hotel) => h.featured)[0] ?? null;

            return (
                <>
                    {hotelsContent.hotels && (
                        <EventSectionWrapper
                            title="Get The Best Hotel Rates"
                            description="Blockworks has partnered with our main event venue to offer our attendees preferred pricing on a limited number of rooms."
                            disableFadeIn
                        >
                            <FeaturedHotel hotel={featuredHotel} title="" disableMapAnimation />
                        </EventSectionWrapper>
                    )}
                </>
            );
        },
    },
    {
        key: 'participating_companies',
        render: (participatingCompanies: ParticipatingCompaniesContent, event: Event) => (
            <>
                {participatingCompanies && (
                    <EventSectionWrapper>
                        <SectionParticipatingCompanies
                            className=""
                            eventSlug={event.slug}
                            participatingCompanies={participatingCompanies}
                            limit={event.customFields?.homepage_num_companies ?? 8}
                            showAllLink
                            hideHeader={!participatingCompanies.show_title}
                        />
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
    {
        key: 'founding_sponsors',
        render: (sponsors: Content, event: Event) => (
            <>
                {sponsors.sponsor_group && (
                    <SectionSponsors
                        className="p-5 md:p-10 xl:px-20 md:pr-32 xl:pr-32"
                        eventSlug={event.slug}
                        sponsorGroup={sponsors.sponsor_group}
                        showAllLink
                    />
                )}
            </>
        ),
    },
    {
        key: 'photo-slider',
        render: (photos: PhotosContent) => (
            <>
                {photos && photos.photo_slider?.length > 0 && (
                    <div className="w-full mt-24 border-t-[10px] border-event-accent">
                        <ImageSlider
                            className="h-auto"
                            imageUrls={photos.photo_slider.map(
                                (photo: any) => nextImageFromCustomFields(photo) as NextImage,
                            )}
                        />
                    </div>
                )}
            </>
        ),
    },
    {
        key: 'quote-slider',
        render: (quotes: Content) => (
            <>
                {quotes.sponsor_group && (
                    <EventSectionWrapper>
                        {quotes.quote_slider?.map((quote: Quote) => (
                            <section
                                key={quote.quote_name}
                                className="flex flex-col justify-start items-start w-full gap-2.5 px-60 py-10 bg-event-accent"
                            >
                                <div className="flex flex-col justify-start items-center self-stretch relative gap-5">
                                    {quote.quote && (
                                        <div className="self-stretch w-[960px] text-[28px] text-center text-white">
                                            <RenderSimpleHtml html={quote.quote} />
                                        </div>
                                    )}
                                    <p className="flex-grow-0 flex-shrink-0 text-lg text-center text-white">
                                        — {quote.quote_name}
                                    </p>
                                </div>
                            </section>
                        ))}
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
    {
        key: 'themes',
        render: (themes: ThemesContent) => (
            <>
                {themes && (
                    <EventSectionWrapper title={themes.nav_title} description={themes.description}>
                        {themes.description && (
                            <div>
                                <RenderSimpleHtml html={themes.description} />
                            </div>
                        )}
                        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 w-full">
                            {themes.items?.map((item: any) => (
                                <div
                                    key={slugify(item.title)}
                                    className="flex flex-col lg:flex-row justify-start items-top gap-5 px-0 w-full border border-gray p-10 h-auto"
                                >
                                    {item.image && (
                                        <div className="min-w-[250px] lg:min-w-[335px] pr-4">
                                            <ImageWithFallback
                                                width={335}
                                                height={185}
                                                src={item.image}
                                                alt={item.title}
                                                className="bg-gray-50 object-cover w-[335px] h-[185px]"
                                            />
                                        </div>
                                    )}
                                    <div className="flex flex-col relative gap-3 px-10 lg:h-42 xl:h-40">
                                        <div>
                                            <hr className="border-t-4 rounded border-t-event-accent w-12 mb-4" />
                                            <p className="text-xl lg:text-3xl font-semibold text-[#000a39]">
                                                {item.title}
                                            </p>
                                            <div className="text-sm lg:text-lg text-gray-500 mt-2">
                                                <RenderSimpleHtml html={item.description} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </EventSectionWrapper>
                )}
            </>
        ),
    },
];
const RenderEventContent = ({ event, speakers }: { event: Event; speakers: EventSpeaker[] }) =>
    event.customFields?.content?.map(item => {
        const section = EventSectionMap.find(s => s.key === item.acf_fc_layout);

        if (section) {
            return section.render(item as any, event, speakers);
        }

        return null;
    });

export default RenderEventContent;
