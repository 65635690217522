import getAllEventsContentSections from '@/components/pages/event/utils/get-all-events-content-sections';
import { Content } from '@/types/events-custom-fields';

const getEventsContentSection = <T = any>(eventContent: Content[], section: string): T | any | null => {
    const content = getAllEventsContentSections(eventContent, section);
    if (!content) {
        return null;
    }

    return content[0]!;
};

export default getEventsContentSection;
