import { useMemo } from 'react';
import { Pluggable } from 'unified';

import { ComponentOverrides, useAbstractProcessor } from './use-abstract-processor';

/**
 * Hook to convert html into react fragments
 *
 * @param html
 * @param rehypeReactOptions
 * @param plugins
 */

const useHtmlProcessor = <T extends Pluggable[], K extends ComponentOverrides>(
    html: string | null,
    components?: K,
    plugins?: T,
) => {
    const { renderSync } = useAbstractProcessor('html', components, plugins);

    return useMemo(() => renderSync(html), [html]);
};

export { useHtmlProcessor };
