import { AgendaDay } from '@/types/pages/api/events/sessionize';

const getRoomById = (agenda: AgendaDay[], roomId: string) => {
    for (const agendaDay of agenda) {
        const found = agendaDay.rooms.find(r => `${r.id}` === roomId);
        if (found) {
            return found;
        }
    }
    return null;
};

export default getRoomById;
