// eslint-disable-next-line
import { useEffect, useRef, useState } from 'react';

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
}

const useIntersectionObserver = ({
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
}: Args = {}): [IntersectionObserverEntry | undefined, React.MutableRefObject<HTMLElement | null>] => {
    const elementRef = useRef<HTMLElement | null>(null);
    const [entry, setEntry] = useState<IntersectionObserverEntry>();

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    const updateEntry = ([e]: IntersectionObserverEntry[]): void => {
        setEntry(e);
    };

    useEffect(() => {
        const node = elementRef?.current;
        const hasIOSupport = !!window.IntersectionObserver;

        if (!hasIOSupport || frozen || !node) return;

        const observerParams = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        // eslint-disable-next-line consistent-return
        return () => observer.disconnect();
    }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]);

    return [entry, elementRef];
};

export { useIntersectionObserver };
