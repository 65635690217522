import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

import copyToClipboard from '@/utils/functions/browser/copy-to-clipboard';

type EventShareBoxProps = {
    className?: string;
    shareText: string;
    shareUrl: string;
};

const EventShareBox = (props: EventShareBoxProps) => {
    const { className, shareText, shareUrl } = props;

    return (
        <div className={cn('w-full py-12', className)}>
            <p className="text-center pb-12 text-[10px] text-center uppercase text-event-accent">SHARE</p>
            <div className="flex flex-col justify-center items-center gap-10">
                <a
                    href={`https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}&via=Blockworks_`}
                    target="_blank"
                    rel="noreferrer"
                    title="Share via Twitter"
                >
                    <svg
                        width={24}
                        height={18}
                        viewBox="0 0 24 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 fill-event-accent"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path d="M23.0757 2.07643C22.245 2.49182 21.4142 2.63028 20.445 2.76874C21.4142 2.2149 22.1065 1.38413 22.3834 0.276435C21.5526 0.830281 20.5834 1.1072 19.4757 1.38413C18.645 0.553358 17.3988 -0.000488281 16.1526 -0.000488281C13.7988 -0.000488281 11.7219 2.07644 11.7219 4.56874C11.7219 4.98413 11.7219 5.26105 11.8603 5.53797C8.12187 5.39951 4.66034 3.59951 2.44495 0.830281C2.02957 1.52259 1.89111 2.2149 1.89111 3.18413C1.89111 4.7072 2.72187 6.09182 3.96803 6.92259C3.27572 6.92259 2.58341 6.64567 1.89111 6.36874C1.89111 8.58413 3.41418 10.3841 5.49111 10.7995C5.07572 10.938 4.66034 10.938 4.24495 10.938C3.96803 10.938 3.69111 10.938 3.41418 10.7995C3.96803 12.5995 5.62957 13.9841 7.70649 13.9841C6.18341 15.2303 4.24495 15.9226 2.02957 15.9226C1.61418 15.9226 1.33726 15.9226 0.921875 15.9226C2.9988 17.1687 5.35264 17.9995 7.84495 17.9995C16.1526 17.9995 20.7219 11.0764 20.7219 5.12259C20.7219 4.98413 20.7219 4.7072 20.7219 4.56874C21.6911 3.87644 22.5219 3.04567 23.0757 2.07643Z" />
                    </svg>
                </a>
                <a
                    href={`http://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    title="Share via Linkedin"
                >
                    <svg
                        width={22}
                        height={21}
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 fill-event-accent"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path d="M20.5148 -0.000488281H1.35234C0.827343 -0.000488281 0.433594 0.393261 0.433594 0.918261V20.212C0.433594 20.6058 0.827343 20.9995 1.35234 20.9995H20.6461C21.1711 20.9995 21.5648 20.6058 21.5648 20.0808V0.918261C21.4336 0.393261 21.0398 -0.000488281 20.5148 -0.000488281ZM6.60234 17.8495H3.58359V7.87451H6.73359V17.8495H6.60234ZM5.15859 6.56201C4.10859 6.56201 3.32109 5.64326 3.32109 4.72451C3.32109 3.67451 4.10859 2.88701 5.15859 2.88701C6.20859 2.88701 6.99609 3.67451 6.99609 4.72451C6.86484 5.64326 6.07734 6.56201 5.15859 6.56201ZM18.2836 17.8495H15.1336V12.9933C15.1336 11.812 15.1336 10.3683 13.5586 10.3683C11.9836 10.3683 11.7211 11.6808 11.7211 12.9933V17.9808H8.57109V7.87451H11.5898V9.18701C11.9836 8.39951 13.0336 7.61201 14.4773 7.61201C17.6273 7.61201 18.1523 9.71201 18.1523 12.337V17.8495H18.2836Z" />
                    </svg>
                </a>
                <a
                    href={`mailto:?subject=${shareText}&body=${shareUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    title="Share via Email"
                >
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 w-6 h-6 relative fill-event-accent"
                        preserveAspectRatio="none"
                    >
                        <path d="M19.15 6.25H6.35C5.47 6.25 4.758 6.97 4.758 7.85L4.75 17.45C4.75 18.33 5.47 19.05 6.35 19.05H19.15C20.03 19.05 20.75 18.33 20.75 17.45V7.85C20.75 6.97 20.03 6.25 19.15 6.25ZM20.75 7.85L12.75 13.45L4.758 7.85C4.77224 7.35781 4.88776 7.11994 5.25 6.75L12.75 11.85L20.25 6.75L20.75 7.85Z" />
                    </svg>
                </a>
                <a
                    className="cursor-pointer"
                    onClick={() => copyToClipboard(shareUrl)}
                    target="_blank"
                    rel="noreferrer"
                    title="Copy link to clipboard"
                >
                    <svg
                        width={30}
                        height={15}
                        viewBox="0 0 30 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 fill-event-accent"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path d="M2.85 7.49951C2.85 4.93451 4.935 2.84951 7.5 2.84951H13.5V-0.000488281H7.5C3.36 -0.000488281 0 3.35951 0 7.49951C0 11.6395 3.36 14.9995 7.5 14.9995H13.5V12.1495H7.5C4.935 12.1495 2.85 10.0645 2.85 7.49951ZM9 8.99951H21V5.99951H9V8.99951ZM22.5 -0.000488281H16.5V2.84951H22.5C25.065 2.84951 27.15 4.93451 27.15 7.49951C27.15 10.0645 25.065 12.1495 22.5 12.1495H16.5V14.9995H22.5C26.64 14.9995 30 11.6395 30 7.49951C30 3.35951 26.64 -0.000488281 22.5 -0.000488281Z" />
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default EventShareBox;
