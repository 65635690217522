import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BecomeASponsorInputs, wpMutations } from '@blockworks/platform/api/wp';
import { Spinner } from '@blockworks/ui/spinner';

type BecomeASponsorFormInputs = Omit<BecomeASponsorInputs, 'sponsorFormId'>;

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    company: yup.string().required('Company is required'),
    roleTitle: yup.string().required('Role/Title is required'),
    calendlyCalendarLink: yup.string(),
    anythingElseWeShouldKnow: yup.string(),
});

const BecomeASponsorForm = ({ sponsorFormId }: { sponsorFormId: number }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
    } = useForm<BecomeASponsorFormInputs>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const submitBecomeASponsorMutation = wpMutations.submitBecomeASponsor.use();

    const onSubmit = handleSubmit(async data => {
        submitBecomeASponsorMutation.mutate({
            name: data.name,
            email: data.email,
            company: data.company,
            roleTitle: data.roleTitle,
            calendlyCalendarLink: data.calendlyCalendarLink,
            anythingElseWeShouldKnow: data.anythingElseWeShouldKnow,
            sponsorFormId: sponsorFormId,
        });
    });

    return (
        <form onSubmit={onSubmit}>
            {submitBecomeASponsorMutation.status !== 'success' && (
                <>
                    <div className="mb-4">
                        <label htmlFor="name" className="block mb-2 text-sm sm:text-base">
                            Name<span className="text-red-500"> *</span>
                        </label>
                        <input
                            id="name"
                            {...register('name')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.name && <p className="text-red-500 text-xs sm:text-sm">{errors.name.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-2 text-sm sm:text-base">
                            Email<span className="text-red-500"> *</span>
                        </label>
                        <input
                            id="email"
                            {...register('email')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.email && <p className="text-red-500 text-xs sm:text-sm">{errors.email.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="company" className="block mb-2 text-sm sm:text-base">
                            Company<span className="text-red-500"> *</span>
                        </label>
                        <input
                            id="company"
                            {...register('company')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.company ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.company && <p className="text-red-500 text-xs sm:text-sm">{errors.company.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="roleTitle" className="block mb-2 text-sm sm:text-base">
                            Role/Title<span className="text-red-500"> *</span>
                        </label>
                        <input
                            id="roleTitle"
                            {...register('roleTitle')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.roleTitle ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.roleTitle && (
                            <p className="text-red-500 text-xs sm:text-sm">{errors.roleTitle.message}</p>
                        )}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="calendlyCalendarLink" className="block mb-2 text-sm sm:text-base">
                            Calendly/Calendar Link
                        </label>
                        <input
                            id="calendlyCalendarLink"
                            {...register('calendlyCalendarLink')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.calendlyCalendarLink ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.calendlyCalendarLink && (
                            <p className="text-red-500 text-xs sm:text-sm">{errors.calendlyCalendarLink.message}</p>
                        )}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="anythingElseWeShouldKnow" className="block mb-2 text-sm sm:text-base">
                            Anything else we should know?
                        </label>
                        <textarea
                            id="anythingElseWeShouldKnow"
                            {...register('anythingElseWeShouldKnow')}
                            className={`w-full p-2 border rounded text-sm sm:text-base ${errors.anythingElseWeShouldKnow ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.anythingElseWeShouldKnow && (
                            <p className="text-red-500 text-xs sm:text-sm">{errors.anythingElseWeShouldKnow.message}</p>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="w-full p-3 text-white bg-black rounded text-sm sm:text-base"
                        style={{ background: 'var(--event-accent-color)' }}
                        disabled={submitBecomeASponsorMutation.isPending}
                    >
                        {submitBecomeASponsorMutation.isPending ? <Spinner size={6} color="white" /> : 'Submit'}
                    </button>
                </>
            )}

            {isSubmitSuccessful && submitBecomeASponsorMutation.status === 'success' && (
                <div className="mt-4 p-4 bg-green-100 border border-green-300 rounded">
                    <p className="text-green-700 font-semibold text-sm sm:text-base">Submission Successful!</p>
                </div>
            )}

            {submitBecomeASponsorMutation.status === 'error' && (
                <div className="flex justify-center items-center mt-4 p-4 bg-red-100 border border-red-300 rounded text-center">
                    <p className="text-red-700 font-semibold text-sm sm:text-base">Submission Failed</p>
                    <p className="text-red-600 mt-1 text-xs sm:text-sm">
                        Error submitting form. Please try again later.
                    </p>
                </div>
            )}
        </form>
    );
};

export default BecomeASponsorForm;
