import { NextImage } from '@/types/next-image';

const nextImage = (image: any): NextImage | null => {
    if (!image || !image?.sizes) {
        return null;
    }

    return {
        url: image.sizes.large,
        dimensions: {
            width: image.sizes['large-width'] ?? image.sizes.large_width,
            height: image.sizes['large-height'] ?? image.sizes.large_height,
        },
    };
};

export default nextImage;
