import * as React from 'react';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import EventHomepageSectionHeader from '@/components/pages/event/layout/components/home/components/event-homepage-section-header';
import TicketsPlan from '@/components/pages/event/layout/components/tickets/tickets-plan';
import { PriceFeature, PricingContent } from '@/types/events-custom-fields';
import stripHtmlTags from '@/utils/functions/string/strip-html-tags';

type SectionTicketsProps = {
    className?: string;
    eventSlug: string;
    pricing: PricingContent;
};

const SectionTickets = (props: SectionTicketsProps) => {
    const { className, pricing } = props;
    const [activePeriod, setActivePeriod] = useState<'Fiat' | 'Crypto'>('Fiat');
    let hasCryptoOption = false;

    const plans =
        pricing.price_cards?.map(card => {
            if (card.second_button_link) {
                hasCryptoOption = true;
            }

            return {
                title: card.title || 'Ticket',
                titleIcon: card.title_icon,
                previousPrice: {
                    Fiat: `${card.price}`,
                    Crypto: `${card.price_crypto || card.price}`,
                },
                price: {
                    Fiat: `${card.price}`,
                    Crypto: `${card.price_crypto || card.price}`,
                },
                // May include textarea newlines
                description: stripHtmlTags(card.sub_title || ''),
                button: {
                    Fiat: card.first_button_link ?? null,
                    Crypto: card.second_button_link ?? null,
                },
                features: (card?.features || []).map((f: PriceFeature) => f.feature || '') || [],
                featured: card?.featured ?? false,
            };
        }) || [];

    return (
        <section className={cn('w-full flex flex-col justify-start gap-5', className)}>
            <EventHomepageSectionHeader title={pricing.nav_title || 'tickets'} description={pricing.text_above} />

            {hasCryptoOption && (
                <div className="flex justify-start items-start">
                    <div className="relative">
                        <RadioGroup value={activePeriod} onChange={setActivePeriod} className="grid grid-cols-2">
                            {['Fiat', 'Crypto'].map(period => (
                                <RadioGroup.Option
                                    key={period}
                                    value={period}
                                    className={cn(
                                        'text-center cursor-pointer border border-gray-300 py-[calc(theme(spacing.3)-1px)] px-[calc(theme(spacing.10)-1px)] text-sm text-gray-700 outline-2 outline-offset-2 transition-colors hover:border-gray-400',
                                        period === 'Fiat' ? '' : '-ml-px',
                                        activePeriod === period ? 'text-transparent' : '',
                                    )}
                                >
                                    <span>{period}</span>
                                </RadioGroup.Option>
                            ))}
                        </RadioGroup>
                        <div
                            aria-hidden="true"
                            className={cn(
                                'pointer-events-none absolute inset-0 z-1 grid grid-cols-2 overflow-hidden transition-all duration-300 bg-black',
                                activePeriod === 'Fiat'
                                    ? '[clip-path:inset(0_50%_0_0)]'
                                    : '[clip-path:inset(0_0_0_calc(50%-1px))]',
                            )}
                        >
                            {['Fiat', 'Crypto'].map(period => (
                                <div
                                    key={period}
                                    className={cn(
                                        'py-3 text-center text-sm font-semibold [&:not(:focus-visible)]:focus:outline-none',
                                        period === 'Crypto' && '-ml-px',
                                    )}
                                >
                                    <span className="text-white">{period}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-2 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3">
                {plans.map(plan => (
                    <TicketsPlan key={plan.title} {...plan} activePeriod={activePeriod} />
                ))}
            </div>

            {pricing.text_below && (
                <div className="text-lg">
                    <RenderSimpleHtml html={pricing.text_below} />
                </div>
            )}
        </section>
    );
};

export default SectionTickets;
