import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

type PageHeaderProps = {
    className?: string;
    title: string;
    description?: string;
    h1ClassName?: string;
    h2ClassName?: string;
};

const PageHeader = (props: PageHeaderProps) => {
    const { title, description, className, h1ClassName, h2ClassName } = props;

    return (
        <div className={cn('flex justify-between items-center flex-grow-0 flex-shrink-0 w-full', className)}>
            <div className="flex justify-between items-end self-stretch">
                <div className="flex flex-col justify-start items-start relative gap-1">
                    <h1
                        className={
                            h1ClassName ||
                            'font-semibold text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-left text-gray-900'
                        }
                    >
                        {title}
                    </h1>
                    {description && (
                        <h2
                            className={
                                h2ClassName ||
                                'line-clamp-3 text-sm md:text-base flex justify-start items-start self-stretch relative gap-2.5 text-left text-light-gray'
                            }
                        >
                            {description}
                        </h2>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
