import * as React from 'react';
import { CountdownTimeDelta } from 'react-countdown/dist/utils';

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }: CountdownTimeDelta) => {
    if (completed) {
        return null;
    }

    return (
        <>
            <div className="text-lg md:text-2xl lg:text-3xl flex flex-row flex-wrap lg:flex-nowrap justify-start align-middle gap-1.5 lg:gap-3 xl:gap-5">
                {days !== 0 && (
                    <div
                        className="flex justify-center items-center gap-1 md:gap-2 lg:gap-3"
                        style={{ borderColor: 'inherit' }}
                    >
                        <p className="text-left font-semibold">{days}</p>
                        <div
                            className="text-md lg:text-lg flex justify-center items-center gap-2.5 pt-2.5 pb-2 border-b"
                            style={{ borderColor: 'currentColor' }}
                        >
                            <p className="text-left uppercase">day{days !== 1 && 's'}</p>
                        </div>
                    </div>
                )}
                {hours !== 0 && (
                    <div className="flex justify-center items-center gap-1 md:gap-2 lg:gap-3">
                        <p className="text-left font-semibold">{hours}</p>
                        <div
                            className="text-md lg:text-lg flex justify-center items-center gap-2.5 pt-2.5 pb-2 border-b"
                            style={{ borderColor: 'currentColor' }}
                        >
                            <p className="text-left uppercase">hour{hours !== 1 && 's'}</p>
                        </div>
                    </div>
                )}
                <div className="flex justify-center items-center gap-1 md:gap-2 lg:gap-3">
                    <p className="text-left font-semibold">{minutes}</p>
                    <div
                        className="text-md lg:text-lg flex justify-center items-center gap-2.5 pt-2.5 pb-2 border-b"
                        style={{ borderColor: 'currentColor' }}
                    >
                        <p className="text-left uppercase">minute{minutes !== 1 && 's'}</p>
                    </div>
                </div>
                <div className="flex justify-center items-center gap-1 md:gap-2 lg:gap-3">
                    <div className="flex justify-start items-start space-x-[-20px]">
                        <p className="text-left font-semibold">{seconds}</p>
                    </div>
                    <div
                        className="text-md lg:text-lg flex justify-center items-center gap-2.5 pt-2.5 pb-2 border-b"
                        style={{ borderColor: 'currentColor' }}
                    >
                        <p className="text-left uppercase">second{seconds !== 1 && 's'}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CountdownRenderer;
