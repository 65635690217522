import { Content } from '@/types/events-custom-fields';

const getAllEventsContentSections = <T = any>(eventContent: Content[], section: string): T | any | null => {
    if (!eventContent || eventContent.length === 0) {
        return null;
    }

    const content = eventContent.filter(ev => ev.acf_fc_layout === section);
    if (content.length === 0) {
        return null;
    }

    return content;
};

export default getAllEventsContentSections;
