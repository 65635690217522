import * as React from 'react';
import { useRef, useState } from 'react';
// @ts-ignore
import ReactGravityForm from 'react-gravity-form';

import GravityButton from '@/components/form/gravity/gravity-button';
import EditorialConfig from '@/editorial-config';

type GravityFormProps = {
    formId: string;
    className?: string;
    onSubmit?: (formData: any, result: any) => void;
    onChange?: (formData: any) => void;
};

type FormResult = {
    message: string;
    error?: any;
};

const GravityForm = (props: GravityFormProps) => {
    const { className, formId, onSubmit, onChange } = props;
    const [, setIsloading] = useState<boolean>(false);

    GravityButton.OnSubmit = () => {
        setIsloading(true);
    };
    GravityButton.ScrollRef = useRef(null);
    const [formConfirmation, setFormConfirmation] = useState<any | null>(null);
    const [formData, setFormData] = useState<any | null>(null);

    const [error, setError] = useState<FormResult | null>(null);
    const [, setSuccess] = useState<FormResult | null>(null);

    const gravityBasicAuth = Buffer.from(
        `${process.env.GRAVITY_FORMS_CONSUMER_KEY}:${process.env.GRAVITY_FORMS_CONSUMER_SECRET}`,
    ).toString('base64');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Basic ${gravityBasicAuth}`);

    const formSubmit = (data: any) => {
        setFormConfirmation(data);
        setSuccess({
            message: data.confirmation_message,
        });
        onSubmit && onSubmit(formData, data);
        setIsloading(false);
        return true;
    };

    const formChange = (data: any) => {
        setFormData(data);
        if (onChange) {
            onChange(data);
        }
        return true;
    };

    const formError = (err: any) => {
        setError({ message: 'There was an error submitting the form.', error: err });
        setIsloading(false);
    };

    return (
        <div className={className} ref={GravityButton.ScrollRef}>
            <div className="w-full relative">
                <ReactGravityForm
                    // backendUrl={`${EditorialConfig.wpApiUrl}gf/v2/forms`}
                    backendUrl={`${EditorialConfig.wpApiUrl}b-gravity/v1/gf/forms`}
                    formID={formId}
                    onChange={formChange} // optional - keep all entered values as parameter
                    onSubmitSuccess={formSubmit} // optional - calls after form has been submitted successfully
                    onError={formError}
                    styledComponents={{
                        Button: GravityButton,
                    }}
                    title={false}
                    populatedFields={{ field: 'value' }}
                    getParams={{}} // optional - pass an Object with your params to send the GF request with query string included
                    sitekey={process.env.RECAPTCHA_SITEKEY}
                />
            </div>
            {formConfirmation && !formConfirmation.is_valid && (
                <div>
                    <p className="py-4 text-red-500">There was an error submitting your form.</p>
                </div>
            )}

            {error && <div className="mt-6 p-6 bg-red-50 border border-red-100 text-red-500">{error.message}</div>}
            {/*
            // Success is already displayed by ReactGravityForm component
            {success && (
                <div className="mt-6 p-6 bg-green-50 border border-green-100 text-green-500">
                    <RenderSimpleHtml html={success.message} />
                </div>
            )}
            */}
        </div>
    );
};

export default GravityForm;
