import * as React from 'react';
import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';

import { format } from '@blockworks/platform/libs/date-fns';

type EventLivestreamProps = {
    title: string;
    id: string;
    session: {
        iframeEmbedUrl: string;
        title?: string;
        startsAt?: string;
        endsAt?: string;
    };
};
const displayIframe = (src: string, title: string) => {
    if (!src) {
        return <div className="mt-6 text-gray-800">No stream available.</div>;
    }

    return (
        <div
            className="w-full h-full mt-6 border-2 p-2 bg-purple-50"
            style={{ borderColor: 'var(--event-accent-color)' }}
        >
            <iframe
                className="w-full border-0"
                height="800px"
                src={src}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    );
};

const EventLivestreamEmbed = (props: EventLivestreamProps) => {
    const { id, title, session } = props;

    return (
        <div key={id} className="w-full h-full mt-12">
            <h2 className="text-2xl flex items-center gap-2 align-middle">
                <MapPinIcon className="inline-block w-6 h-6" /> {title}
            </h2>
            <div className="flex flex-col gap-2 sm:flex-row sm:gap-3 text-slate-500 ">
                <h3 className="flex items-center gap-1 text-xs tracking-wide">{session.title}</h3>
                {session.endsAt && session.startsAt && (
                    <div className="flex items-center gap-1 text-xs tracking-wide">
                        <ClockIcon className="inline-block w-3.5 h-3.5" />{' '}
                        {format(new Date(session.startsAt), 'h:mm aaa')} -{' '}
                        {format(new Date(session.endsAt), 'h:mm aaa')}
                    </div>
                )}
            </div>
            {displayIframe(session.iframeEmbedUrl || '', session.title ?? '')}
        </div>
    );
};

export default EventLivestreamEmbed;
