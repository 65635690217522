const timeStringToDate = (time: string): Date => {
    let date: Date;
    try {
        date = new Date(time);
    } catch (err) {
        console.error(err);
        date = new Date();
    }
    return date;
};

export default timeStringToDate;
