import React from 'react';

import { useTrack } from '@blockworks/platform/services/analytics';

type SortDirections = 'asc' | 'desc';
type SortConfig = {
    key: string;
    // used to filter 3-dimensional array by second dimension data (e.g. items[0].firstKey[1].secondKey)
    secondKey?: string;
    direction: SortDirections;
};

const parseDate = (date: number | string | Date): Date | null => {
    const parsed = new Date(date);
    if (!Number.isNaN(parsed.getDate())) {
        return parsed;
    }
    return null;
};

export default <T extends { [key: string]: any }>(
    items: T[],
    config: SortConfig | null = null,
): {
    items: T[];
    requestSort: (key: string, secondKey?: string, direction?: 'asc' | 'desc') => void;
    sortConfig: SortConfig | null;
} => {
    const [sortConfig, setSortConfig] = React.useState(config);
    const track = useTrack();

    const sortedItems = React.useMemo(() => {
        const sortableItems = items;
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.direction === 'desc') {
                    [b, a] = [a, b];
                }

                if (sortConfig.secondKey) {
                    return a[sortConfig.key][sortConfig.secondKey]?.localeCompare(
                        b[sortConfig.key][sortConfig.secondKey]!,
                        undefined,
                        { sensitivity: 'base' },
                    );
                }
                // If the value is a number, do a simple sort by number
                const aNumber = Number(a[sortConfig.key]);
                if (!Number.isNaN(aNumber)) {
                    return aNumber - Number(b[sortConfig.key]);
                }

                // Parse values to date
                const aDate = parseDate(a[sortConfig.key] as string);
                const bDate = parseDate(b[sortConfig.key] as string);
                if (aDate && !bDate) {
                    // Downrank current date value if it is invalid
                    return -1;
                }
                // If both values are a date, sort using it's timestamp
                if (aDate && bDate) {
                    return aDate.getTime() - bDate.getTime();
                }

                // Use localCompare for strings (and if localCompare is available on the value)
                if (a[sortConfig.key]?.localeCompare) {
                    return a[sortConfig.key]!.localeCompare(b[sortConfig.key]!, undefined, {
                        sensitivity: 'base',
                    });
                }

                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key: string, secondKey?: string, direction?: 'asc' | 'desc') => {
        let selectedDirection: SortDirections = 'asc';
        if (!direction) {
            if (sortConfig && sortConfig.direction === 'asc') {
                selectedDirection = 'desc';
            }
        } else {
            selectedDirection = direction;
        }
        setSortConfig({ key, secondKey, direction: selectedDirection });
        track('SortableData.RequestSort', { key, secondKey, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};
