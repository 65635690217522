import * as React from 'react';
import { useMemo } from 'react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import EventSectionHeader from '@/components/pages/event/layout/components/event-section-header';
import { EventSpeaker } from '@/types/event-speaker';

type SectionSpeakersProps = {
    eventSlug: string;
    speakers: EventSpeaker[];

    title?: string;
    description?: string;
    className?: string;
    highlightedSpeaker?: EventSpeaker | null;
    displayCount?: number;
    showAllSpeakersLink: boolean;
    hideHeader?: boolean;
};

const SectionSpeakers = (props: SectionSpeakersProps) => {
    const {
        title,
        className,
        description,
        displayCount,
        speakers,
        highlightedSpeaker,
        eventSlug,
        showAllSpeakersLink,
        hideHeader = false,
    } = props;
    const speakersToDisplay = useMemo(() => {
        if (!speakers || speakers.length === 0) {
            return [];
        }

        return speakers.slice(0, displayCount);
    }, [speakers, displayCount]);

    if (!speakers || speakers.length === 0) {
        return <></>;
    }

    return (
        <section className={cn('flex flex-col gap-5 xs:items-center xs:justify-center', className)}>
            {!hideHeader && (
                <EventSectionHeader
                    variant="plain"
                    title={title ?? 'Speakers'}
                    description={description ?? 'Join 100s of your colleagues from the world of institutional finance.'}
                    linkTitle={
                        showAllSpeakersLink ? (
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                    <Link
                                        href={`/event/${eventSlug}/speakers`}
                                        className="flex-grow-0 flex-shrink-0 text-base text-left uppercase text-event-accent"
                                    >
                                        all {title ?? 'Speakers'}
                                    </Link>
                                </div>
                                <svg
                                    width={16}
                                    height={9}
                                    viewBox="0 0 16 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="flex-grow-0 flex-shrink-0 fill-event-accent"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path d="M12.01 3.79688H0V5.79688H12.01V8.79688L16 4.79688L12.01 0.796875V3.79688Z" />
                                </svg>
                            </div>
                        ) : (
                            ''
                        )
                    }
                    linkUrl={`/event/${eventSlug}/speakers`}
                />
            )}
            <div className="w-full flex md:align-left xs:justify-center sm:justify-start">
                <div className="w-full grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 justify-items-center">
                    {highlightedSpeaker && (
                        <Link
                            href={`/speaker/${highlightedSpeaker.slug}`}
                            className="xs:row-span-2 xs:col-span-2 w-full border p-4 hover:border-event-accent"
                            key={highlightedSpeaker.id}
                        >
                            <div className="">
                                <ImageWithFallback
                                    src={highlightedSpeaker.thumbnail || ''}
                                    width={380}
                                    height={380}
                                    alt=""
                                    className="relative object-fill w-full"
                                />
                            </div>
                            <div className="flex flex-col justify-center items-center self-stretch flex-grow relative gap-1 px-5 py-10 bg-[#000a39]">
                                <p className="text-base text-center uppercase text-[#5a7aed]">keynote</p>
                                <p className="text-2xl text-center capitalize text-white">{highlightedSpeaker.title}</p>
                                <p className="text-lg text-center text-[#e0e3eb]">
                                    {highlightedSpeaker.custom_fields?.speaker_job?.value}
                                    {highlightedSpeaker.custom_fields?.speaker_job?.value &&
                                        highlightedSpeaker.custom_fields?.speaker_company?.value &&
                                        ' | '}
                                    {highlightedSpeaker.custom_fields?.speaker_company?.value}
                                </p>
                            </div>
                        </Link>
                    )}

                    {speakersToDisplay.map(speaker => (
                        <Link
                            href={`/speaker/${speaker.slug}`}
                            className="w-full flex flex-col gap-3 bg-white border p-4 hover:border-event-accent"
                            key={speaker.id}
                        >
                            <div className="w-full">
                                <ImageWithFallback
                                    src={speaker.thumbnail || ''}
                                    width={155}
                                    height={155}
                                    alt=""
                                    className="relative w-full object-fill"
                                />
                            </div>
                            <div className="w-full flex flex-col justify-center pt-2">
                                <p className="text-sm font-bold text-gray-800">{speaker.title}</p>
                                <p className="text-xs text-[#979797]">{speaker.custom_fields?.speaker_job?.value}</p>
                                {speaker.custom_fields?.speaker_company?.value && (
                                    <p className="text-xs text-event-accent mt-2">
                                        {speaker.custom_fields?.speaker_company?.value}
                                    </p>
                                )}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SectionSpeakers;
