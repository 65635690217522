import * as React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

import { cn } from '@blockworks/ui/utils';

import BuyTicketsButton from '@/components/pages/event/layout/components/tickets/components/buy-tickets-button';
import { Link } from '@/types/events-custom-fields';
import { Image } from '@/types/webinar-custom-fields';

type TicketsPlanPrice = {
    Fiat: string;
    Crypto: string;
};

type TicketsPlanProps = {
    className?: string;
    title: string;
    titleIcon?: Image;
    previousPrice: TicketsPlanPrice;
    price: TicketsPlanPrice;
    description: string;
    button: {
        Fiat: Link | null;
        Crypto: Link | null;
    };
    features: string[];
    featured: boolean;
    activePeriod: 'Fiat' | 'Crypto';
};

const TicketsPlan = (props: TicketsPlanProps) => {
    const { className, title, titleIcon, price, previousPrice, description, button, features, featured, activePeriod } =
        props;

    return (
        <section
            className={cn(
                'flex flex-col overflow-hidden p-6 border min-w-[275px] hover:border-gray-400',
                featured ? 'order-first lg:order-none bg-event-accent' : 'bg-white',
                className,
            )}
        >
            <h3
                className={cn(
                    'flex items-center space-x-2 text-2xl capitalize font-normal py-2',
                    featured ? 'text-white' : 'text-gray-900',
                )}
            >
                <span>{title}</span>
                {titleIcon && titleIcon.url && <img src={titleIcon.url} alt="" className="ml-2 w-5 h-5" />}
            </h3>
            <p
                className={cn(
                    'relative mt-5 flex text-4xl font-semibold tracking-tight',
                    featured ? 'text-white' : 'text-event-accent',
                )}
            >
                {price.Fiat === price.Crypto ? (
                    price.Fiat
                ) : (
                    <>
                        <span
                            aria-hidden={activePeriod === 'Crypto'}
                            className={cn(
                                'transition duration-300 flex flex-col',
                                activePeriod === 'Crypto' && 'pointer-events-none translate-x-6 select-none opacity-0',
                            )}
                        >
                            <span className="text-xl text-gray-300 line-through">{previousPrice.Fiat}</span>
                            <span>{price.Fiat}</span>
                        </span>
                        <span
                            aria-hidden={activePeriod === 'Fiat'}
                            className={cn(
                                'absolute left-0 top-0 transition duration-300',
                                activePeriod === 'Fiat' && 'pointer-events-none -translate-x-6 select-none opacity-0',
                            )}
                        >
                            <span>{price.Crypto}</span>
                        </span>
                    </>
                )}
            </p>
            <p className={cn('mt-1 text-sm', featured ? 'text-gray-100' : 'text-gray-400')}>{description}</p>
            <div className="order-last mt-6">
                <ul role="list" className={cn('-my-2 text-md', featured ? 'text-gray-500' : 'text-gray-900')}>
                    {features.map((feature: any) => (
                        <li key={feature} className="flex py-2">
                            <CheckIcon
                                className={cn('h-6 w-6 flex-none', !featured ? 'text-event-accent' : 'text-white')}
                            />
                            <span className="ml-4">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
            {button[activePeriod] && (
                <BuyTicketsButton
                    url={button[activePeriod]?.url ?? ''}
                    target={activePeriod === 'Crypto' ? '_blank' : (button[activePeriod].target ?? '')}
                    title={button[activePeriod]?.title || 'Buy Ticket'}
                    price={price[activePeriod]}
                    featured={featured}
                />
            )}
        </section>
    );
};

export default TicketsPlan;
