import * as React from 'react';

import { cn } from '@blockworks/ui/utils';

type SectionFollowProps = {
    className?: string;
    children: React.ReactNode | JSX.Element | string;
};

const SectionFollow = (props: SectionFollowProps) => {
    const { className, children } = props;

    return (
        <section
            className={cn(
                'flex justify-center items-center w-full relative gap-3 px-5 lg:px-10 xl:px-20 py-10 bg-event-accent',
                className,
            )}
        >
            <svg
                width={25}
                height={21}
                viewBox="0 0 25 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M24.8068 3.10457C23.8837 3.56611 22.9606 3.71995 21.8837 3.8738C22.9606 3.25841 23.7299 2.33534 24.0376 1.10457C23.1145 1.71995 22.0376 2.02764 20.8068 2.33534C19.8837 1.41226 18.4991 0.796875 17.1145 0.796875C14.4991 0.796875 12.1914 3.10457 12.1914 5.8738C12.1914 6.33534 12.1914 6.64303 12.3453 6.95072C8.19141 6.79688 4.34525 4.79687 1.88371 1.71995C1.42218 2.48918 1.26833 3.25841 1.26833 4.33534C1.26833 6.02764 2.19141 7.56611 3.57602 8.48918C2.80679 8.48918 2.03756 8.18149 1.26833 7.8738C1.26833 10.3353 2.96064 12.3353 5.26833 12.7969C4.80679 12.9507 4.34525 12.9507 3.88371 12.9507C3.57602 12.9507 3.26833 12.9507 2.96064 12.7969C3.57602 14.7969 5.42217 16.3353 7.72987 16.3353C6.03756 17.72 3.88371 18.4892 1.42218 18.4892C0.960637 18.4892 0.652945 18.4892 0.191406 18.4892C2.4991 19.8738 5.11448 20.7969 7.88371 20.7969C17.1145 20.7969 22.1914 13.1046 22.1914 6.48918C22.1914 6.33534 22.1914 6.02764 22.1914 5.8738C23.2683 5.10457 24.1914 4.18149 24.8068 3.10457Z"
                    fill="white"
                />
            </svg>
            <div className="text-xl text-left text-white whitespace-normal">
                <>{children}</>
            </div>
        </section>
    );
};

export default SectionFollow;
