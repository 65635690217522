import { AgendaDay } from '@/types/pages/api/events/sessionize';

const getRoomSessions = (agenda: AgendaDay) => {
    if (!agenda?.rooms || agenda.rooms.length === 0) {
        return [];
    }

    const roomSessions = [];
    for (const room of agenda.rooms) {
        const session = room?.sessions.find(sess => sess.liveUrl);
        if (session) {
            roomSessions.push({
                title: room.name,
                id: room.id,
                session,
            });
        }
    }

    return roomSessions;
};

export default getRoomSessions;
