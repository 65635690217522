import * as React from 'react';
import { useMemo } from 'react';

import EventSectionWrapper from '@/components/pages/event/layout/components/event-section-wrapper';
import SectionSpeakers from '@/components/pages/event/layout/components/section-speakers';
import useSpeakers from '@/components/pages/event/layout/hooks/use-speakers';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';
import getEventsContentSection from '@/components/pages/event/utils/get-events-content-section';
import type { SpeakersContent } from '@/types/events-custom-fields';

const SpeakersLayout = (props: TabLayoutProps) => {
    const { speakers: passedSpeakers, event } = props;
    const { highlightedSpeaker, speakers } = useSpeakers(event, passedSpeakers || []);

    const speakerContent = useMemo(
        () => getEventsContentSection<SpeakersContent>(event.customFields?.content || [], 'speakers'),
        [event.customFields],
    );

    if (!passedSpeakers || passedSpeakers.length === 0) {
        return (
            <div className="p-5 md:p-10 xl:px-20 md:pr-32 h-96">
                Speakers for <span className="font-semibold">{event.title}</span> are not available, yet. Please follow
                our socials for updates.
            </div>
        );
    }

    return (
        <div className="w-full min-h-[1200px]">
            <EventSectionWrapper padding="no">
                <SectionSpeakers
                    className="bg-white"
                    eventSlug={event.slug}
                    title={speakerContent.nav_title}
                    highlightedSpeaker={highlightedSpeaker}
                    speakers={speakers ?? []}
                    showAllSpeakersLink={false}
                />
            </EventSectionWrapper>
        </div>
    );
};

export default SpeakersLayout;
