import { NextImage } from '@/types/next-image';

const nextImageFromUrl = (url: string, width: number, height: number): NextImage | null => {
    return {
        url,
        dimensions: {
            width,
            height,
        },
    };
};

export default nextImageFromUrl;
