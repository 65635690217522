import { fetcher } from '@blockworks/platform/api';

import EditorialConfig from '@/editorial-config';
import type { EventSpeaker } from '@/types/event-speaker';

const fetchEventSpeakers = async (eventId: string): Promise<EventSpeaker[] | null> => {
    if (!eventId) {
        throw new Error('Please provide a valid event id.');
    }

    const speakers = await fetcher<false, void, EventSpeaker[]>({
        url: `${EditorialConfig.wpApiUrl}b-headless/v1/events/${eventId}/speakers`,
        // Override user agent with blockworks-editorial since fetcher is using blockworks-research
        headers: { 'User-Agent': fetcher.UserAgent.Editorial },
        // Omit credentials to prevent CORS issues
        credentials: 'omit',
    });

    if (!speakers || speakers.length === 0) {
        return null;
    }

    return speakers;
};

export default fetchEventSpeakers;
