import * as React from 'react';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import EventSectionHeader from '@/components/pages/event/layout/components/event-section-header';
import type { ParticipatingCompaniesContent } from '@/types/events-custom-fields';

type SectionParticipatingCompaniesProps = {
    className?: string;
    eventSlug: string;
    participatingCompanies: ParticipatingCompaniesContent;
    limit?: number;
    showAllLink: boolean;
    hideHeader?: boolean;
};

const SectionParticipatingCompanies = (props: SectionParticipatingCompaniesProps) => {
    const { className, participatingCompanies, eventSlug, showAllLink, limit, hideHeader } = props;

    return (
        <section className={cn('flex flex-col items-center justify-center gap-3', className)}>
            {!hideHeader && (
                <EventSectionHeader
                    variant="plain"
                    title={participatingCompanies.nav_title}
                    description={participatingCompanies.text_above}
                    linkTitle={
                        showAllLink ? (
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                                    <Link
                                        href={`/event/${eventSlug}/participating-companies`}
                                        className="flex-grow-0 flex-shrink-0 text-base text-left uppercase text-event-accent"
                                    >
                                        all {participatingCompanies.nav_title}
                                    </Link>
                                </div>
                                <svg
                                    width={16}
                                    height={9}
                                    viewBox="0 0 16 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="flex-grow-0 flex-shrink-0 fill-event-accent"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path d="M12.01 3.79688H0V5.79688H12.01V8.79688L16 4.79688L12.01 0.796875V3.79688Z" />
                                </svg>
                            </div>
                        ) : (
                            ''
                        )
                    }
                    linkUrl={`/event/${eventSlug}/attendees`}
                />
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 w-full">
                {participatingCompanies?.companies
                    ?.slice(0, limit ?? participatingCompanies?.companies?.length ?? 8)
                    .map((company, idx) => (
                        <div
                            key={idx}
                            className="flex flex-col justify-center items-center align-center flex-grow gap-5 p-10 bg-white border border-[#b4b4b4]/[0.35] overflow-hidden"
                        >
                            <ImageWithFallback
                                src={company.logo || ''}
                                width={225}
                                height={225}
                                alt=""
                                className="relative object-fit"
                            />
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default SectionParticipatingCompanies;
