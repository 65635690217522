import React from 'react';

interface HighlightYearProps {
    title: string;
}

const HighlightYear: React.FC<HighlightYearProps> = ({ title }) => {
    const words = title.split(' ');

    return (
        <p>
            {words
                .map((word, index) => {
                    if (/^\d{4}$/.test(word)) {
                        return (
                            <span key={index} className="opacity-50">
                                {word}
                            </span>
                        );
                    }
                    return word;
                })
                // @ts-ignore
                .reduce((prev, curr, i) => {
                    return [...prev, i > 0 && ' ', curr];
                }, [])}
        </p>
    );
};

export default HighlightYear;
