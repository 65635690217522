import * as React from 'react';

import BlockworksSvgLogo from '@/components/pages/event/layout/components/blockworks-svg-logo';
import HighlightYear from '@/components/pages/event/layout/components/home/components/highlight-year';
import { NextImage } from '@/types/next-image';

type Props = {
    title: string;
    subTitle?: string;
    location: string;
    dateString: React.ReactNode | string;
    presentedByImage?: NextImage | null;
    showPresentedBy?: boolean;
    hideHeroTitle?: boolean;
    variant?: 'default' | 'plate';
};

const EventHeroContent = (props: Props) => {
    const { variant, title, subTitle, location, dateString, presentedByImage, showPresentedBy, hideHeroTitle } = props;

    if (variant === 'plate') {
        return (
            <div className="relative -mt-12 w-full h-full flex flex-col justify-center align-center gap-6 border-4 border-white bg-opacity-50 bg-event-accent">
                {showPresentedBy && (
                    <div className="z-10 flex flex-col md:flex-row justify-center items-center gap-3 mb-12">
                        <BlockworksSvgLogo />
                        {presentedByImage && (
                            <picture className="flex flex-col md:flex-row justify-center items-center gap-3">
                                <p className="text-lg text-center">and</p>
                                <img src={presentedByImage.url} alt="Blockworks" className="h-[20px] md:h-[40px]" />
                            </picture>
                        )}
                        {presentedByImage ? (
                            <p className="text-lg text-center">present</p>
                        ) : (
                            <p className="text-lg text-center">presents</p>
                        )}
                    </div>
                )}
                <div className="z-10 mx-auto flex flex-col justify-center items-center lg:w-[924px] h-[429px] relative gap-0 p-10 border-[10px] border-white bg-event-accent">
                    <div className="flex justify-start items-center gap-1">
                        <p className="text-center text-xl font-normal opacity-75">{dateString}</p>
                    </div>
                    {!hideHeroTitle && (
                        <h1
                            itemProp="name"
                            className="z-10 px-2 md:px-12 w-full font-bol tracking-tight text-5xl md:text-7xl lg:text-8xl xl:text-8xl text-center subpixel-antialiased"
                        >
                            <HighlightYear title={title} />
                        </h1>
                    )}
                    {subTitle && (
                        <div className="z-10 mt-2 text-center w-full">
                            <div className="mx-auto" itemProp="location" itemScope itemType="https://schema.org/Place">
                                <h3 itemProp="address" className="text-center text-2xl font-normal opacity-75">
                                    {location}
                                </h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="-mt-12 w-full h-full flex flex-col justify-center align-center gap-6 bg-event-accent border-4 border-white">
            {showPresentedBy && (
                <div className="z-10 flex flex-col md:flex-row justify-center items-center gap-3">
                    <BlockworksSvgLogo />
                    {presentedByImage && (
                        <picture className="flex flex-col md:flex-row justify-center items-center gap-3">
                            <p className="text-lg text-center">and</p>
                            <img src={presentedByImage.url} alt="Blockworks" className="h-[20px] md:h-[40px]" />
                        </picture>
                    )}
                    {presentedByImage ? (
                        <p className="text-lg text-center">present</p>
                    ) : (
                        <p className="text-lg text-center">presents</p>
                    )}
                </div>
            )}

            {!subTitle && (
                <div className="flex align-center justify-center">
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-2 lg:gap-5">
                        <div className="flex justify-start items-center gap-2">
                            <svg
                                width="12"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hidden xxs:flex"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M9.51653 9.5866C8.58055 10.5226 6.00181 13.1013 6.00181 13.1013C6.00181 13.1013 3.49224 10.5917 2.48709 9.5866C0.545969 7.64547 0.545969 4.49829 2.48709 2.55716C4.42822 0.616037 7.57541 0.616037 9.51653 2.55716C11.4577 4.49829 11.4577 7.64547 9.51653 9.5866Z"
                                    stroke="white"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.86577 6.07188C7.86577 7.10132 7.03125 7.93584 6.00181 7.93584C4.97238 7.93584 4.13785 7.10132 4.13785 6.07188C4.13785 5.04244 4.97238 4.20792 6.00181 4.20792C7.03125 4.20792 7.86577 5.04244 7.86577 6.07188Z"
                                    stroke="white"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div
                                className="flex justify-start items-center gap-1"
                                itemProp="location"
                                itemScope
                                itemType="https://schema.org/Place"
                            >
                                <p itemProp="address" className="text-base text-center uppercase">
                                    {location}
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-start items-center gap-2">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hidden xxs:flex"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M4.30599 3.76799V1.10132M9.63932 3.76799V1.10132M3.63932 6.43465H10.306M2.30599 13.1013H11.6393C12.3757 13.1013 12.9727 12.5044 12.9727 11.768V3.76799C12.9727 3.03161 12.3757 2.43465 11.6393 2.43465H2.30599C1.56961 2.43465 0.972656 3.03161 0.972656 3.76799V11.768C0.972656 12.5044 1.56961 13.1013 2.30599 13.1013Z"
                                    stroke="white"
                                    strokeWidth="0.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex justify-start items-center gap-1">
                                <p className="text-base text-center uppercase">{dateString}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!hideHeroTitle && (
                <h1
                    itemProp="name"
                    className="z-10 px-2 md:px-12 w-full font-black tracking-tight text-5xl md:text-7xl lg:text-8xl xl:text-9xl text-center subpixel-antialiased"
                >
                    {title}
                </h1>
            )}
            {subTitle && (
                <div className="z-10 text-center md:text-right absolute left-0 right-0 bottom-4 md:bottom-10 px-10">
                    <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl">{subTitle}</h2>
                    <div className="" itemProp="location" itemScope itemType="https://schema.org/Place">
                        <p itemProp="address" className="text-base text-center md:text-right uppercase font-semibold">
                            {location}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventHeroContent;
