import * as React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { useTrack } from '@blockworks/platform/services/analytics';

import ButtonLink from '@/components/display/buttons/button-link';
import SecondaryButton from '@/components/display/buttons/secondary-button';
import ImageSlider from '@/components/pages/event/layout/components/home/components/image-slider';
import { NextImage } from '@/types/next-image';

export type Hotel = {
    featured?: boolean;
    deal?: boolean;
    name: string;
    description: string;
    primaryLink: string | null;
    infoLink: string | null;
    price: string | null;
    originalPrice: string;
    images: NextImage[];
    address: string;
};

type HotelCardsProps = {
    hotels: Hotel[];
    className?: string;
};

const HotelCards = (props: HotelCardsProps) => {
    const { hotels, className } = props;
    const track = useTrack();

    return (
        <div className={className}>
            {hotels.map(hotel => (
                <div key={hotel.name} className="group relative border-b border-r border-gray-200 p-4 sm:p-4">
                    <div className="relative">
                        <div className="h-[195px] overflow-hidden bg-gray-200 group-hover:opacity-90">
                            <ImageSlider
                                noAutoPlay
                                imageUrls={hotel.images.map(images => ({
                                    url: images.url,
                                    dimensions: {
                                        width: images.dimensions?.width || 1024,
                                        height: images.dimensions?.height || 768,
                                    },
                                }))}
                            />
                        </div>
                        <div className="relative mt-4">
                            {hotel.infoLink && (
                                <a
                                    href={hotel.infoLink}
                                    target="_blank"
                                    className="absolute right-0"
                                    onClick={() => {}}
                                >
                                    <InformationCircleIcon className="text-black h-6 w-6" />
                                </a>
                            )}
                            <h3 className="text-md font-bold text-gray-900">{hotel.name}</h3>
                            <p className="mt-1 text-sm text-gray-500">{hotel.description}</p>
                        </div>
                        {(hotel.price || hotel.originalPrice) && (
                            <div className="absolute inset-x-0 top-0 flex items-end justify-end overflow-hidden p-4 gap-2">
                                <div
                                    aria-hidden="true"
                                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                                />
                                {hotel.originalPrice && (
                                    <p className="relative text-lg font-semibold text-red-500 line-through">
                                        {hotel.originalPrice}
                                    </p>
                                )}
                                {hotel.price && (
                                    <p className="relative text-lg font-semibold text-white">{hotel.price}/night</p>
                                )}
                            </div>
                        )}
                    </div>
                    {hotel.primaryLink && (
                        <div className="mt-6">
                            <ButtonLink
                                className="bg-event-accent text-white border border-transparent hover:border-event-accent hover:bg-white hover:text-event-accent"
                                onClick={() => {
                                    track('Clicked Reserve Now Button', {
                                        name: hotel.name,
                                        url: hotel.primaryLink,
                                    });
                                }}
                                target="_blank"
                                link={hotel.primaryLink}
                            >
                                <span>RESERVE NOW</span>
                            </ButtonLink>
                        </div>
                    )}
                    {!hotel.primaryLink && hotel.infoLink && (
                        <div className="mt-6">
                            <SecondaryButton
                                onClick={() => {
                                    track('Clicked Info Button', {
                                        name: hotel.name,
                                        url: hotel.infoLink,
                                    });
                                }}
                                target="_blank"
                                link={hotel.infoLink}
                            >
                                <span>DETAILS</span>
                            </SecondaryButton>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default HotelCards;
