import { createApi } from '../config/create-api';
import { fetcher } from '../config/fetcher';

import {
    BecomeASponsorInputs,
    GetCategoriesRequest,
    GetCategoriesResponse,
    GetEventByIdRequest,
    GetEventByIdResponse,
    GetEventSpeakearsByIdRequest,
    GetEventSpeakearsByIdResponse,
    GetEventsRequest,
    GetEventsResponse,
    GetPostsByCategoryRequest,
    GetPostsByCategoryResponse,
    WP_ENDPOINTS,
} from './wp.model';

const wpApi = createApi(build => ({
    get: {
        events: build<GetEventsResponse, GetEventsRequest>({
            cachePrefix: 'events',
            baseUrl: 'wordpress',
            query: ({ slug, perPage = 1, page = 1, offset, order = 'desc', orderBy = 'date' }) => ({
                uri: WP_ENDPOINTS.GET.EVENTS(),
                params: {
                    slug,
                    per_page: perPage,
                    page,
                    order,
                    order_by: orderBy,
                    offset,
                },
                options: {
                    credentials: 'omit',
                },
            }),
        }),
        eventById: build<GetEventByIdResponse, GetEventByIdRequest>({
            cachePrefix: 'event-by-id',
            baseUrl: 'wordpress',
            query: ({ id }) => ({
                uri: WP_ENDPOINTS.GET.EVENT_BY_ID({ id }),
                options: {
                    credentials: 'omit',
                },
            }),
        }),
        eventSpeakersById: build<GetEventSpeakearsByIdResponse, GetEventSpeakearsByIdRequest>({
            cachePrefix: 'event-speakers-by-id',
            baseUrl: 'wordpress',
            query: ({ id }) => ({
                uri: WP_ENDPOINTS.GET.EVENT_SPEAKERS({ id }),
                options: {
                    credentials: 'omit',
                },
            }),
        }),
        categories: build<GetCategoriesResponse, GetCategoriesRequest>({
            cachePrefix: 'categories',
            baseUrl: 'wordpress',
            query: ({ perPage = 1, order = 'desc', orderBy = 'name' }) => ({
                uri: WP_ENDPOINTS.GET.CATEGORIES(),
                params: {
                    per_page: perPage,
                    order,
                    orderby: orderBy,
                },
                options: {
                    credentials: 'omit',
                },
            }),
        }),
        postsByCategory: build<GetPostsByCategoryResponse, GetPostsByCategoryRequest>({
            cachePrefix: 'posts-by-categories',
            baseUrl: 'wordpress',
            query: ({ perPage = 1, page, order = 'desc', orderBy = 'date', embed, categories, context }) => ({
                uri: WP_ENDPOINTS.GET.POSTS_BY_CATEGORY(),
                params: {
                    per_page: perPage,
                    page,
                    order,
                    orderby: orderBy,
                    categories,
                    context,
                    _embed: embed,
                },
                options: {
                    credentials: 'omit',
                },
            }),
        }),
    },
    post: {
        submitBecomeASponsor: build<any, BecomeASponsorInputs>({
            cachePrefix: 'become-a-sponsor',
            baseUrl: 'wordpress',
            query: ({
                name,
                email,
                company,
                roleTitle,
                calendlyCalendarLink,
                anythingElseWeShouldKnow,
                sponsorFormId,
            }) => ({
                uri: WP_ENDPOINTS.POST.SUBMIT_GRAVITY_FORM({ id: sponsorFormId }),
                options: {
                    method: fetcher.Method.Post,
                    credentials: 'omit',
                    body: {
                        input_1: name,
                        input_3: email,
                        input_4: company,
                        input_5: roleTitle,
                        input_7: calendlyCalendarLink,
                        input_6: anythingElseWeShouldKnow,
                    },
                },
            }),
        }),
    },
}));

export { wpApi };
