import * as React from 'react';

import SectionSponsors from '@/components/pages/event/layout/components/section-sponsors';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';
import { Content } from '@/types/events-custom-fields';

type SponsorsLayoutProps = TabLayoutProps & {
    sponsors: Content;
};

const SponsorsLayout = (props: SponsorsLayoutProps) => {
    const { event, sponsors: sponsorsAll } = props;

    const sponsors = sponsorsAll?.sponsor_group || [];

    return (
        <div className="p-5 md:p-10 xl:px-20 md:pr-32 xl:pr-32 pb-96">
            {sponsors.length > 0 && <SectionSponsors eventSlug={event.slug} sponsorGroup={sponsors} showAllLink />}
        </div>
    );
};

export default SponsorsLayout;
