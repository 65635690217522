import * as React from 'react';
import { useEffect } from 'react';
import { Transition } from '@headlessui/react';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import EventHomepageSectionHeader from '@/components/pages/event/layout/components/home/components/event-homepage-section-header';

import { useIntersectionObserver } from '../hooks/use-intersection-observer';

type Props = {
    title?: string;
    description?: string;
    textBelow?: string;
    children: React.ReactNode;
    disableFadeIn?: boolean;
    padding?: 'no' | 'small' | 'default' | 'large';
};
const EventSectionWrapper = (props: Props) => {
    const { disableFadeIn, title, description, textBelow, children, padding } = props;
    const [entry, elementRef] = useIntersectionObserver({ threshold: 0.3, rootMargin: '-12%' });
    const isVisible = !!entry?.isIntersecting;
    const [isAppeared, setIsAppeared] = React.useState(false);

    useEffect(() => {
        if (isVisible && !isAppeared) {
            setIsAppeared(true);
        }
    }, [isAppeared, isVisible]);

    const content = (
        <>
            <EventHomepageSectionHeader title={title ?? ''} description={description} />

            <div className="mt-10 w-full">{children}</div>
            {textBelow && <RenderSimpleHtml html={textBelow} />}
        </>
    );

    return (
        <section
            className={cn(
                (padding === undefined || padding === 'default') && ' py-10',
                padding === 'no' && ' py-0',
                padding === 'small' && ' py-5',
                padding === 'large' && ' py-20',
                'w-full flex flex-col items-center justify-start gap-10 px-5 md:mr-10 md:px-10 xl:px-20',
            )}
            ref={elementRef}
        >
            {disableFadeIn ? (
                content
            ) : (
                <Transition
                    appear
                    unmount={false}
                    show={isAppeared}
                    className="w-full"
                    enter="transition-all duration-1000"
                    enterFrom="opacity-0 translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-1000"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-4 !block"
                >
                    {content}
                </Transition>
            )}
        </section>
    );
};

export default EventSectionWrapper;
