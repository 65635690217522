import { useReducer, useState } from 'react';
import { StarIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

import { format, isWithinInterval } from '@blockworks/platform/libs/date-fns';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { cn } from '@blockworks/ui/utils';

import SessionPopup from '@/components/pages/event/layout/components/sessions/components/session-popup';
import SpeakerPopup from '@/components/pages/event/layout/components/sessions/components/speaker-popup';
import { Event } from '@/types/article-type';
import { SessionFull, SessionSpeaker } from '@/types/pages/api/events/sessionize';

interface SessionCardProps {
    onSelectSession: (id: string) => void;
    getSpeakerList: (speakers: SessionFull['speakers']) => SessionSpeaker[];
    isSelected: boolean;
    session: SessionFull;
    event: Event;
    showRoom?: boolean;
}

const SessionCard = ({
    event,
    session,
    isSelected,
    onSelectSession,
    getSpeakerList,
    showRoom = false,
}: SessionCardProps) => {
    const { id, startsAt, endsAt, speakers, title, room, description } = session;
    const [showPopup, toggle] = useReducer(open => !open, false);
    const [activeSpeaker, setSpeaker] = useState<SessionSpeaker>();

    const start = format(new Date(startsAt), 'h:mm aaa');
    const end = format(new Date(endsAt), 'h:mm aaa');
    const isLive = isWithinInterval(new Date(), { start: new Date(startsAt), end: new Date(endsAt) });

    return (
        <div className="relative">
            <div
                className={cn(
                    'flex flex-col gap-3 border p-5',
                    isSelected ? 'border-event-accent' : '',
                    isLive ? 'border- outline-2 outline-gray-700' : '',
                )}
            >
                <div className={isLive ? 'absolute -m-1 top-0 right-0' : 'hidden'}>
                    <Link href={`/event/${event.slug}/livestreams`} className="h-3 w-3 relative flex">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-event-accent opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-event-accent"></span>
                    </Link>
                </div>
                <div className="flex flex-col gap-1 relative">
                    <div className="flex justify-between">
                        <div>
                            {showRoom && <div className="text-xs tracking-wide rounded-md font-bold">{room}</div>}
                            <div className="text-xs tracking-wide text-event-accent">
                                {start} - {end}
                            </div>
                        </div>
                        <button
                            className={`flex-none self-start ${
                                showRoom ? 'mt-1' : ''
                            } focus-visible:outline-event-accent`}
                            onClick={() => onSelectSession(id)}
                        >
                            <StarIcon
                                className={cn(
                                    'w-5 hover:stroke-event-accent transition-all',
                                    isSelected ? 'fill-event-accent stroke-event-accent' : '',
                                )}
                            />
                        </button>
                    </div>
                    <button
                        className={cn(
                            'font-bold tracking-wide text-start hover:text-event-accent outline-event-accent',
                            speakers.length === 0 && !description ? 'pointer-events-none' : '',
                        )}
                        onClick={toggle}
                    >
                        {title}
                    </button>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="flex-auto relative group h-[32px]">
                        {getSpeakerList(speakers).map((speaker, idx) => {
                            return (
                                <div
                                    key={speaker.name}
                                    className="relative inline-block w-[24px] group-hover:w-[40px] group-focus-within:w-[40px] transition-all"
                                    style={{
                                        zIndex: `${speakers.length - idx}`,
                                    }}
                                >
                                    <button
                                        aria-describedby={`${speaker.name}`}
                                        className="relative h-[32px] w-[32px] group/speaker inline-block focus-visible:outline-none"
                                        onClick={() => setSpeaker(speaker)}
                                    >
                                        <ImageWithFallback
                                            src={speaker.thumbnail || ''}
                                            width={32}
                                            height={32}
                                            alt={speaker.name || ''}
                                            className="group-focus-visible/speaker:outline-event-accent group-focus-visible/speaker:outline group-focus-visible/speaker:outline-2 rounded-full object-cover aspect-1"
                                        />
                                        <div
                                            id={`${speaker.name}`}
                                            className={cn(
                                                'group-focus-visible/speaker:block group-hover/speaker:block mt-2 hidden absolute translate-x-[-50%] left-[16px] p-1 rounded text-xxs bg-event-accent text-white w-max',
                                                `after:content-[''] after:w-[16px] after:h-[16px] after:absolute after:bg-event-accent after:top-0 after:left-[50%] after:rotate-45 after:translate-x-[-50%] after:z-[-1]`,
                                            )}
                                        >
                                            {speaker.name}
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <SessionPopup
                event={event}
                isOpen={showPopup}
                onClose={toggle}
                session={session}
                getSpeakerList={getSpeakerList}
            />
            {activeSpeaker && (
                <SpeakerPopup
                    isRoot
                    speaker={activeSpeaker}
                    event={event}
                    isOpen
                    onClose={() => setSpeaker(undefined)}
                />
            )}
        </div>
    );
};

export default SessionCard;
