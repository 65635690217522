import * as React from 'react';
import Image from 'next/image';
import { ProgramBox, ProgramItem, useProgram } from 'planby';

import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

interface CustomProgramItem extends ProgramItem {}

const ProgramItemLayout = ({ program, ...rest }: CustomProgramItem) => {
    const [showModal, setShowModal] = React.useState(false);

    const { styles, formatTime, set12HoursTimeFormat, isLive } = useProgram({
        program,
        ...rest,
    });

    const { data } = program;
    const { title, since, till } = data;

    const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
    const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

    return (
        <ProgramBox width={styles.width} style={styles.position}>
            <>
                {showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/* content */}
                                <div className="border relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            {title}
                                            <div className="flex items-center gap-1">
                                                <svg
                                                    width={10}
                                                    height={10}
                                                    viewBox="0 0 8 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="flex-grow-0 flex-shrink-0"
                                                    preserveAspectRatio="xMidYMid meet"
                                                >
                                                    <path
                                                        d="M5.47 5.93L5.92 5.48L4.33 3.88V1.87H3.73V4.12L5.47 5.93ZM4 8C3.45333 8 2.93667 7.895 2.45 7.685C1.96333 7.475 1.53833 7.18833 1.175 6.825C0.811667 6.46167 0.525 6.03667 0.315 5.55C0.105 5.06333 0 4.54667 0 4C0 3.45333 0.105 2.93667 0.315 2.45C0.525 1.96333 0.811667 1.53833 1.175 1.175C1.53833 0.811667 1.96333 0.525 2.45 0.315C2.93667 0.105 3.45333 0 4 0C4.54667 0 5.06333 0.105 5.55 0.315C6.03667 0.525 6.46167 0.811667 6.825 1.175C7.18833 1.53833 7.475 1.96333 7.685 2.45C7.895 2.93667 8 3.45333 8 4C8 4.54667 7.895 5.06333 7.685 5.55C7.475 6.03667 7.18833 6.46167 6.825 6.825C6.46167 7.18833 6.03667 7.475 5.55 7.685C5.06333 7.895 4.54667 8 4 8ZM4 7.4C4.93333 7.4 5.73333 7.06667 6.4 6.4C7.06667 5.73333 7.4 4.93333 7.4 4C7.4 3.06667 7.06667 2.26667 6.4 1.6C5.73333 0.933333 4.93333 0.6 4 0.6C3.06667 0.6 2.26667 0.933333 1.6 1.6C0.933333 2.26667 0.6 3.06667 0.6 4C0.6 4.93333 0.933333 5.73333 1.6 6.4C2.26667 7.06667 3.06667 7.4 4 7.4Z"
                                                        fill="#254CDA"
                                                    />
                                                </svg>
                                                <p className="flex-grow-0 flex-shrink-0 text-sm text-left uppercase text-[#254cda]">
                                                    {sinceTime} - {tillTime}
                                                </p>
                                            </div>
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/* body */}
                                    <div className="relative p-6 flex-auto">
                                        {data.speakers && data.speakers.length > 0 && (
                                            <>
                                                <h2 className="text-xl mb-4 uppercase text-headline border-bottom">
                                                    Speakers
                                                </h2>
                                                <div className="my-4 text-slate-500 text-lg leading-relaxed grid grid-cols-1 lg:grid-cols-3 gap-4">
                                                    {data.speakers.map((speaker: any) => (
                                                        <Link
                                                            key={speaker.title}
                                                            className="border p-4"
                                                            href={`/speaker/${speaker.slug}`}
                                                            target="_blank"
                                                        >
                                                            <Image
                                                                className="relative inline-block h-24 w-24"
                                                                src={speaker.thumbnail}
                                                                alt="speaker"
                                                                width={96}
                                                                height={96}
                                                            />
                                                            <h3 className="mt-2 text-xl font-bold text-black">
                                                                {speaker.title}{' '}
                                                                {speaker.is_moderator && (
                                                                    <span
                                                                        className="px-2 py-1 rounded-md bg-gray-700 text-white uppercase text-xs"
                                                                        style={{
                                                                            backgroundColor:
                                                                                speaker.moderator_tag_background_color,
                                                                        }}
                                                                    >
                                                                        Moderator
                                                                    </span>
                                                                )}
                                                            </h3>
                                                            <h4 className="text-md font-normal">
                                                                {speaker.custom_fields?.speaker_company?.value}
                                                            </h4>
                                                            <p className="text-xs">
                                                                {speaker.custom_fields?.speaker_job?.value}
                                                            </p>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <button
                                        className="px-4 py-2 uppercase m-4 text-white bg-event-accent"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-[125] bg-black" />
                    </>
                ) : null}
            </>

            <div
                className={cn(
                    'flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-10 p-4 bg-white border',
                    isLive ? 'border-primary' : 'border-[#b4b4b4]/[0.35]',
                    'hover:border-primary',
                    'cursor-pointer',
                    'mt-2 whitespace-normal overflow-auto',
                )}
                onClick={() => setShowModal(true)}
            >
                <div className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-5">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-3">
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-44 relative gap-1">
                            <svg
                                width={8}
                                height={8}
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 fill-event-accent"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path d="M5.47 5.93L5.92 5.48L4.33 3.88V1.87H3.73V4.12L5.47 5.93ZM4 8C3.45333 8 2.93667 7.895 2.45 7.685C1.96333 7.475 1.53833 7.18833 1.175 6.825C0.811667 6.46167 0.525 6.03667 0.315 5.55C0.105 5.06333 0 4.54667 0 4C0 3.45333 0.105 2.93667 0.315 2.45C0.525 1.96333 0.811667 1.53833 1.175 1.175C1.53833 0.811667 1.96333 0.525 2.45 0.315C2.93667 0.105 3.45333 0 4 0C4.54667 0 5.06333 0.105 5.55 0.315C6.03667 0.525 6.46167 0.811667 6.825 1.175C7.18833 1.53833 7.475 1.96333 7.685 2.45C7.895 2.93667 8 3.45333 8 4C8 4.54667 7.895 5.06333 7.685 5.55C7.475 6.03667 7.18833 6.46167 6.825 6.825C6.46167 7.18833 6.03667 7.475 5.55 7.685C5.06333 7.895 4.54667 8 4 8ZM4 7.4C4.93333 7.4 5.73333 7.06667 6.4 6.4C7.06667 5.73333 7.4 4.93333 7.4 4C7.4 3.06667 7.06667 2.26667 6.4 1.6C5.73333 0.933333 4.93333 0.6 4 0.6C3.06667 0.6 2.26667 0.933333 1.6 1.6C0.933333 2.26667 0.6 3.06667 0.6 4C0.6 4.93333 0.933333 5.73333 1.6 6.4C2.26667 7.06667 3.06667 7.4 4 7.4Z" />
                            </svg>
                            <p className="flex-grow-0 flex-shrink-0 text-xs text-left uppercase text-event-accent">
                                {sinceTime} - {tillTime}
                            </p>
                        </div>
                    </div>
                    <p className="text-lg text-left text-[#000a39]">
                        {title}

                        {title.indexOf('Coffee') !== -1 && (
                            <p className="text-center mt-4">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 51 50"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M39.2209 19.0444H38.8209V19.4444V27.7778C38.8209 33.6958 34.0278 38.4889 28.1098 38.4889H11.4431C5.52517 38.4889 0.732031 33.6958 0.732031 27.7778V0.4H11.0431V6.47458L6.16989 10.3786C5.73225 10.7097 5.48759 11.2465 5.48759 11.7778V23.6111C5.48759 24.6098 6.27778 25.4 7.27648 25.4H18.3876C19.3863 25.4 20.1765 24.6098 20.1765 23.6111V11.7778C20.1765 11.2464 19.9318 10.7097 19.4941 10.3785L14.6209 6.47458V0.4H44.7765C47.6389 0.4 49.932 2.69314 49.932 5.55556V13.8889C49.932 16.7248 47.6376 19.0444 44.7765 19.0444H39.2209ZM44.7765 14.2889H45.1765V13.8889V5.55556V5.15556H44.7765H39.2209H38.8209V5.55556V13.8889V14.2889H39.2209H44.7765ZM0.732031 49.6V44.8444H44.3765V49.6H0.732031Z"
                                        fill="#254CDA"
                                        stroke="#254CDA"
                                        strokeWidth="0.8"
                                    />
                                </svg>
                            </p>
                        )}
                    </p>
                    {data.speakers && data.speakers.length > 0 && (
                        <div className="flex justify-start items-center relative gap-3">
                            <div className="isolate flex -space-x-3 overflow-hidden">
                                {data.speakers.map((s: any, idx: number) => (
                                    <div key={s.title}>
                                        <ImageWithFallback
                                            className="relative inline-block h-10 w-10 rounded-full ring-2 ring-white"
                                            style={{
                                                zIndex: data.speakers.length - idx,
                                            }}
                                            width={30}
                                            height={30}
                                            src={s.thumbnail}
                                            alt=""
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/*
                    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3">
                        <div className="flex justify-start items-start flex-grow gap-1">
                            <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative px-3 py-2 bg-event-accent">
                                <p className="flex-grow-0 flex-shrink-0 text-[10px] text-center capitalize text-white">
                                    Financial services
                                </p>
                            </div>
                            <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative px-3 py-2 bg-event-accent">
                                <p className="flex-grow-0 flex-shrink-0 text-[10px] text-center capitalize text-white">
                                    markets
                                </p>
                            </div>
                        </div>
                        <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.668 13.5H13.668V19.5H11.668V13.5H5.66797V11.5H11.668V5.5H13.668V11.5H19.668V13.5Z"
                                fill="#2F2F2F"
                            />
                            <rect x="1.16797" y={1} width={23} height={23} rx="11.5" stroke="#2F2F2F" />
                        </svg>
                    </div>
                    */}
                </div>
            </div>
        </ProgramBox>
    );
};

export default ProgramItemLayout;
