import { useMemo } from 'react';

import getEventsContentSection from '@/components/pages/event/utils/get-events-content-section';
import type { Event } from '@/types/article-type';
import { EventSpeaker } from '@/types/event-speaker';
import type { SpeakersContent } from '@/types/events-custom-fields';

const useSpeakers = (event: Event, speakers: EventSpeaker[]) => {
    const speakerData = useMemo(
        () => getEventsContentSection<SpeakersContent>(event.customFields?.content || [], 'speakers'),
        [event.customFields],
    );

    const highlightedSpeaker = speakerData?.has_keynote_speaker && speakers?.length ? speakers[0]! : null;

    return {
        speakers: speakerData?.has_keynote_speaker && speakers.length ? speakers.slice(1) : speakers,
        highlightedSpeaker,
    };
};

export default useSpeakers;
