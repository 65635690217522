import * as React from 'react';
import { useRef } from 'react';
import { useRouter } from 'next/router';

import EventHero from '@/components/pages/event/layout/components/event-hero';
import EventMainMenu from '@/components/pages/event/layout/components/event-main-menu';
import Menu from '@/components/pages/event/layout/components/menu';
import EventShareBox from '@/components/pages/event/layout/event-share-box';
import nextImageFromCustomFields from '@/components/pages/events/components/utils/next-image-from-custom-fields';
import nextImageFromUrl from '@/components/pages/events/components/utils/next-image-from-url';
import EditorialConfig from '@/editorial-config';
import type { Event } from '@/types/article-type';
import { BannerStyle } from '@/types/events-custom-fields';

type EventPageLayoutProps = {
    event: Event;
    accentColor?: string;
    children: React.ReactNode | JSX.Element | JSX.Element[];
    hideTabs: string[];
    dynamicTabs: typeof Menu;
};

const EventPageLayout = (props: EventPageLayoutProps) => {
    const router = useRouter();
    const { tab } = router.query;

    const { event, children, accentColor = '#254cda', hideTabs, dynamicTabs } = props;

    const contentRef = useRef<HTMLDivElement>(null);

    const scrollIntoContent = () => contentRef?.current && contentRef.current.scrollIntoView();
    React.useEffect(() => {
        if (tab) {
            scrollIntoContent();
        }
    }, [tab]);

    return (
        <div
            className="w-full relative"
            itemScope
            itemType="https://schema.org/Event"
            style={{ '--event-accent-color': accentColor } as any}
        >
            <EventMainMenu
                routerTab={(tab as string) ?? 'home'}
                title={event.title}
                slug={event.slug}
                secondaryButton={event.customFields.header_button_2_link}
                primaryButton={event.customFields.header_button_1_link}
                city={event.customFields?.city}
                hideTabs={hideTabs}
                dynamicTabs={dynamicTabs}
                isLive={event.customFields.is_live ?? false}
                sponsorFormId={event.customFields.header_sponsor_button_formid}
                accentColor={accentColor}
            />

            {(tab === 'home' || tab === undefined) && (
                <EventHero
                    key={event.slug}
                    title={event.title}
                    subTitle={event.customFields?.subtitle}
                    headerHeight={event.customFields?.hero_banner_height || ''}
                    darkText={event.customFields?.dark_header_text || false}
                    type={event.type}
                    image={nextImageFromUrl(
                        event.thumbnail.url,
                        event.thumbnail.dimensions.width,
                        event.thumbnail.dimensions.height,
                    )}
                    imageUrl={event.imageUrl}
                    videoPlaybackId={event.videoPlaybackId}
                    startDate={event.customFields?.date_and_time ? new Date(event.customFields?.date_and_time) : null}
                    endDate={
                        event.customFields?.date_and_time_end ? new Date(event.customFields?.date_and_time_end) : null
                    }
                    dateString={event.customFields?.text_date || ''}
                    description={event.content || ''}
                    location={event.customFields?.location || ''}
                    learnMoreLink={event.permalink}
                    hideHeroTitle={event.customFields?.hide_hero_title}
                    showPresentedBy={
                        event.customFields?.show_presented_by === undefined ||
                        event.customFields?.show_presented_by === true
                    }
                    presentedByImage={
                        event.customFields?.presented_by_image
                            ? nextImageFromCustomFields(event.customFields?.presented_by_image)
                            : null
                    }
                    registerLink={
                        event.customFields.registration_button_link?.url ||
                        event.customFields.header_button_1_link?.url ||
                        null
                    }
                    variant={(event.customFields?.banner_style as BannerStyle) || 'default'}
                    bannerOverlay={event.customFields?.banner_overlay}
                />
            )}
            <div className="w-full relative" ref={contentRef} style={{ scrollMargin: '120pt' }}>
                <div className="absolute right-0 h-full">
                    <div className="z-40 hidden md:block sticky top-40 mb-40 right-0 w-28 bg-white bg-opacity-50 hover:bg-opacity-100 rounded-bl-xl">
                        <EventShareBox
                            shareText={`${event.title} @ ${event.customFields.location} | ${event.customFields.text_date}`}
                            shareUrl={`${EditorialConfig.SITE_URL}/event/${event.slug}${tab ? `/${tab}` : ''}`}
                        />
                    </div>
                </div>
                <>{children}</>
            </div>
        </div>
    );
};

export default EventPageLayout;
