import * as React from 'react';
import { useRef } from 'react';
import MuxVideo from '@mux/mux-video-react';
import { isEmpty, isNil } from 'lodash-es';
import Image from 'next/image';

import { cn } from '@blockworks/ui/utils';

import { NextImage } from '@/types/next-image';

import EventHeroContent from './event-hero-content';

type EventHeroProps = {
    className?: string;
    type?: string;
    title: string;
    subTitle?: string; // @deprecated Use `bannerOverlay` instead.
    location: string; // @deprecated Use `bannerOverlay` instead.
    dateString: React.ReactNode | string; // @deprecated Use `bannerOverlay` instead.
    presentedByImage?: NextImage | null; // @deprecated Use `bannerOverlay` instead.
    showPresentedBy?: boolean; // @deprecated Use `bannerOverlay` instead.
    hideHeroTitle?: boolean; // @deprecated Use `bannerOverlay` instead.
    startDate: Date | null;
    endDate: Date | null;
    description: string;
    registerLink?: string | null;
    registerText?: string;
    learnMoreLink?: string;
    learnMoreText?: string;
    imageUrl?: string | null;
    videoPlaybackId?: string | null;
    image?: NextImage | null;
    darkText?: boolean;
    headerHeight?: number | string;
    variant?: 'default' | 'plate';
    bannerOverlay?: string;
};

const EventHero = (props: EventHeroProps) => {
    const {
        className,
        title,
        subTitle, // Deprecated
        location, // Deprecated
        dateString, // Deprecated
        presentedByImage, // Deprecated
        showPresentedBy, // Deprecated
        hideHeroTitle, // Deprecated
        startDate,
        endDate,
        imageUrl,
        videoPlaybackId,
        darkText,
        headerHeight,
        variant,
        bannerOverlay,
    } = props;
    const headerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [playable, setPlayable] = React.useState(true);
    const textColor = darkText ? 'text-black' : 'text-white';
    const heightClassName = headerHeight === '16:9' ? 'h-[460px] lg:h-[863px]' : 'h-[460px] lg:h-[665px]';

    // Check if autoplay is available,
    React.useEffect(() => {
        videoRef.current
            ?.play()
            .then(() => {})
            .catch(() => setPlayable(false));
    }, []);

    return (
        <header
            ref={headerRef}
            className={cn(
                'relative mt-12 top-0 left-0 bg-no-repeat bg-cover items-center bg-gradient-to-r from-sky-500 to-indigo-500',
                textColor,
                className,
                heightClassName,
            )}
            // @ts-ignore
            style={{ '--tw-gradient-from': 'var(--event-accent-color)' }}
        >
            {videoPlaybackId && (
                <MuxVideo
                    ref={videoRef}
                    style={{ height: '100%', maxWidth: '100%' }}
                    playbackId={videoPlaybackId}
                    metadata={{
                        video_title: title,
                    }}
                    aria-placeholder="Event video"
                    className="pointer-events-none absolute object-cover z-10 lg:h-[100%] w-[100%]"
                    streamType="on-demand"
                    muted
                    loop
                    nonce={undefined}
                />
            )}
            {videoPlaybackId && (
                <Image
                    className={cn(
                        'absolute h-[460px] lg:h-[665px] object-cover object-center',
                        playable ? 'z-[1]' : 'z-10',
                    )}
                    alt=""
                    src={`https://image.mux.com/${videoPlaybackId}/animated.webp`}
                    fill
                    quality={100}
                />
            )}
            {imageUrl && (
                <Image
                    className="absolute z-[1] h-[460px] lg:h-[665px] object-cover object-center"
                    alt=""
                    src={imageUrl}
                    fill
                    quality={100}
                />
            )}
            {/* EventHeroContent should be deprecated in favor of bannerOverlay */}
            {!isNil(bannerOverlay) && !isEmpty(bannerOverlay) ? (
                <div className="relative -mt-12 w-full h-full flex flex-col justify-center">
                    <div className="z-10 mx-auto flex flex-col justify-center items-center w-[275px] lg:w-[500px] relative">
                        <Image
                            src={bannerOverlay}
                            alt="Event Banner Overlay"
                            layout="intrinsic"
                            width={500}
                            height={350}
                            objectFit="contain"
                        />
                    </div>
                </div>
            ) : (
                <EventHeroContent
                    title={title}
                    subTitle={subTitle}
                    location={location}
                    dateString={dateString}
                    presentedByImage={presentedByImage}
                    showPresentedBy={showPresentedBy}
                    hideHeroTitle={hideHeroTitle}
                    variant={variant}
                />
            )}

            {startDate && <meta itemProp="startDate" content={startDate.toISOString()} />}
            {endDate && <meta itemProp="endDate" content={endDate.toISOString()} />}
            {imageUrl && <meta itemProp="image" content={imageUrl} />}
        </header>
    );
};

export default EventHero;
