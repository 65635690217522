import * as React from 'react';

import SectionParticipatingCompanies from '@/components/pages/event/layout/components/section-participating-companies';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';
import { ParticipatingCompaniesContent } from '@/types/events-custom-fields';

type ParticipatingCompaniesLayoutProps = TabLayoutProps & {
    participatingCompanies: ParticipatingCompaniesContent | null;
};

const ParticipatingCompaniesLayout = (props: ParticipatingCompaniesLayoutProps) => {
    const { event, participatingCompanies } = props;

    return (
        <div className="p-5 md:p-10 xl:px-20 md:pr-32 xl:pr-32 pb-96">
            {participatingCompanies && (
                <SectionParticipatingCompanies
                    eventSlug={event.slug}
                    participatingCompanies={participatingCompanies}
                    showAllLink={false}
                />
            )}
        </div>
    );
};

export default ParticipatingCompaniesLayout;
