import * as React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { RenderSimpleHtml } from '@blockworks/ui/legacy/string';
import { cn } from '@blockworks/ui/utils';

import { Content } from '@/types/events-custom-fields';

type FaqsLayoutProps = {
    faqs: Content | null;
};

const FaqsLayout = (props: FaqsLayoutProps) => {
    const { faqs } = props;

    if (faqs === null) {
        return null;
    }

    return (
        <div className="p-5 md:p-10 xl:px-20 md:pr-32 pb-96">
            {faqs?.text_above && (
                <div className="mb-12">
                    <RenderSimpleHtml html={faqs?.text_above!} />
                </div>
            )}

            <section className="flex flex-col items-center justify-center gap-10">
                {faqs.faq_category?.map((faqGroup: any, index: number) => (
                    <>
                        <div key={index} className="w-full">
                            <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                {faqGroup.category_name}
                            </h3>
                        </div>
                        <dl className="w-full space-y-6 divide-y divide-gray-200">
                            {faqGroup.category_item?.map((faq: any) => (
                                <Disclosure as="div" key={faq.question} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <ChevronDownIcon
                                                            className={cn(
                                                                open ? '-rotate-180' : 'rotate-0',
                                                                'h-6 w-6 transform',
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <div className="text-base text-gray-500">
                                                    <RenderSimpleHtml html={faq.answer} />
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </>
                ))}
            </section>

            {faqs?.text_below && (
                <div className="mt-12">
                    <RenderSimpleHtml html={faqs?.text_below!} />
                </div>
            )}
        </div>
    );
};

export default FaqsLayout;
