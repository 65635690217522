import React from 'react';

import { cn } from '@blockworks/ui/utils';

type QuoteSectionProps = {
    className?: string;
    title: string;
    label: string;
    children: string | React.ReactNode | JSX.Element | JSX.Element[];
};

const QuoteSection = (props: QuoteSectionProps) => {
    const { title, label, children, className } = props;

    return (
        <section
            className={cn('flex gap-10 border-t-0 border-r-0 border-b-0 border-l-4 border-event-accent', className)}
        >
            <div className="flex flex-col justify-start items-start w-full gap-2.5 pl-5 md:pl-10 lg:pl-14">
                <div className="flex flex-col justify-center items-center self-stretch">
                    <div className="flex flex-col justify-start items-start self-stretch gap-3">
                        {label && <span className="text-sm text-left uppercase text-[#5637cd]">{label}</span>}
                        {title && (
                            <div className="mt-10 flex flex-col justify-center items-start self-stretch gap-5">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl text-left text-[#110b29]">{title}</h3>
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default QuoteSection;
