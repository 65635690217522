import { Hotel } from '@/components/pages/event/layout/components/hotel-booking/components/hotel-cards';

const dimensions = {
    width: 1024,
    height: 768,
};
const hotels: Hotel[] = [
    {
        featured: true,
        name: 'Kimpton Hotel Van Zandt',
        address: '605 Davis Street, Austin, TX 78701, United States',
        description: 'Standard Room - 0.2 miles from Austin Convention Center',
        primaryLink: 'https://registration.experientevent.com/Showblk231/flow/hos#!/registrant//Profile/',
        price: '$329',
        originalPrice: '$386',
        infoLink:
            'https://www.booking.com/hotel/us/van-zandt-a-kimpton.html?aid=356980&label=gog235jc-1FCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIF4AIB&checkin=2023-09-10;checkout=2023-09-14;dest_id=20126394;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1692187874;srpvid=f7da55afc4f8017a;type=total;ucfs=1&',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/339410146.jpg?k=84c47d25a4d3a32507dd2a6f4ca48d1b4cc6b3240e5d7d8eb1d73d4f583244d9&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/269537457.jpg?k=995435e568a4072d2212cc4e2057253c88e0557dd8b7c4e6dea25bdb829f894a&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/269064170.jpg?k=07ca51c0c209ccc2b7b1794fc41d093191b24ad134140e2e53e944ce85390493&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/339410169.jpg?k=551cf3eee7e459bcc2a0052cb97b2b9dc00e48318c8c0529e27fdbc8e2085b1c&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/88896697.jpg?k=dfb4536687c214ec91065c50e72a3e0412891b82683de0bc62248eca73dd54e1&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        deal: true,
        name: 'Hyatt Place Austin Downtown',
        address: '211 E 3rd Street, Austin, TX 78701, United States',
        description: 'Standard Room - 0.1 miles from Austin Convention Center',
        primaryLink: 'https://registration.experientevent.com/Showblk231/flow/hos#!/registrant//Profile/',
        price: '$254',
        originalPrice: '$270',
        infoLink:
            'https://www.booking.com/hotel/us/jw-marriott-austin.html?label=gog235jc-1DCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQPoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIE4AIB&aid=356980&ucfs=1&arphpl=1&checkin=2023-09-10&checkout=2023-09-14&dest_id=1123441&dest_type=hotel&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=ef0f56da820b01a4&srepoch=1692188471&all_sr_blocks=112344119_94582707_0_0_0&highlighted_blocks=112344119_94582707_0_0_0&matching_block_id=112344119_94582707_0_0_0&sr_pri_blocks=112344119_94582707_0_0_0__203998&from_sustainable_property_sr=1&from=searchresults#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/135177427.jpg?k=aab5686b5afafb4efd80ae4713e5fdabcddb364cdcf0f428868b8fee21eadeea&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/222604954.jpg?k=7774ca48f533e15e36235c7c5b354c7bd4b366e41cb567aa0a8c368be6757932&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/222604933.jpg?k=cdf384c907c9c0da125d7f00c81b779731d3f11d043c5f7132d8df8ea49b3c16&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/222604918.jpg?k=3c8d42ed9a22a29d556f129ff75d48fbb5f2ccc788a6f62c4a0078bd652101c7&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/115951298.jpg?k=8653d04920efbd0da81a58c6ec202e31455e5356d76c91694638793176c595f0&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/115952014.jpg?k=12a73ca319e2931e6dd56dcd73e321e19d628d7036c80fe6dbab4a740046a119&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        deal: true,
        name: 'Austin Marriot Downtown',
        address: '304 E Cesar Chavez Street, Austin, TX 78701, United States',
        description: 'Queen Room - 0.1 miles from Austin Convention Center',
        primaryLink: 'https://registration.experientevent.com/Showblk231/flow/hos#!/registrant//Profile/',
        price: '$279',
        originalPrice: '$386',
        infoLink:
            'https://www.booking.com/hotel/us/austin-marriott-downtown.html?label=gog235jc-1DCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQPoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIE4AIB&aid=356980&ucfs=1&arphpl=1&checkin=2023-09-10&checkout=2023-09-14&dest_id=6186870&dest_type=hotel&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=3580570ddc1b0259&srepoch=1692188573&all_sr_blocks=618687008_241504868_0_0_0&highlighted_blocks=618687008_241504868_0_0_0&matching_block_id=618687008_241504868_0_0_0&sr_pri_blocks=618687008_241504868_0_0_0__181590&from_sustainable_property_sr=1&from=searchresults#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426579540.jpg?k=f028e0bff3e88f42edc1d79d369e40c8fa7afaa690db9bea6e709bb41efc4bd2&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426579557.jpg?k=dd0638f556c853f8d98493e5c1fd1549948c50c052164e4052378acdae159a56&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426579538.jpg?k=c633aedd79c94b7c3758d0ef83650efe09172f6861f35f272e18117c57fad78e&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/427229727.jpg?k=c25b6a1de8d13ade3c8727648251f42cd76b1dda9f92d56b5635722eb7868713&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426579563.jpg?k=5a2fd65dcb595ace99e4c1b901cdd40b687b563bca28a318108dc475cc0baa0c&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426579525.jpg?k=5fa73f7a5cc8f49bf3edb0fae10ece3af9fcaf03e26e157ef0a7defba708c3ed&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/427229730.jpg?k=247f34e1b91d65a01754326e06254958613fe2032efa6522bde37a0b0f711269&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        deal: true,
        name: 'W Austin',
        address: '200 Lavaca Street, Austin, TX 78701, United States',
        description: 'Deluxe Room - 0.9 miles from Austin Convention Center',
        primaryLink: 'https://registration.experientevent.com/Showblk231/flow/hos#!/registrant//Profile/',
        price: '$369',
        originalPrice: '$446',
        infoLink:
            'https://www.booking.com/hotel/us/w-austin.html?aid=356980&label=gog235jc-1FCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIF4AIB&all_sr_blocks=26990320_94382832_0_2_0;checkin=2023-09-10;checkout=2023-09-14;dest_id=269903;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=26990320_94382832_0_2_0;hpos=1;matching_block_id=26990320_94382832_0_2_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=26990320_94382832_0_2_0__178542;srepoch=1692787383;srpvid=77a14b5ae9e10096;type=total;ucfs=1&#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426573871.jpg?k=9337c6d2bac61b161a30f95e392be79da4799b4d9056426703200555c2411140&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/461357360.jpg?k=f759f3528b2923292219d3790a82918f9c67eb9561359debf4dbb2fd3778f1c9&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/461357384.jpg?k=2e4632445f78bf830c1e15af29e5d317426293bb7aca60bb61221c47b223ece3&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/462984854.jpg?k=384b9d9de6a9491a0dc415a45771a06992d0bf75840c4d8d3fc137d340b8b0fe&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        deal: true,
        name: 'JW Marriott Austin',
        address: '110 E 2nd Street, Austin, TX 78701, United States',
        description: 'Standard Room - 0.3 miles from Austin Convention Center',
        primaryLink: null,
        price: '',
        originalPrice: '',
        infoLink:
            'https://www.booking.com/hotel/us/jw-marriott-austin.html?label=gog235jc-1DCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQPoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIE4AIB&aid=356980&ucfs=1&arphpl=1&checkin=2023-09-10&checkout=2023-09-14&dest_id=1123441&dest_type=hotel&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=ef0f56da820b01a4&srepoch=1692188471&all_sr_blocks=112344119_94582707_0_0_0&highlighted_blocks=112344119_94582707_0_0_0&matching_block_id=112344119_94582707_0_0_0&sr_pri_blocks=112344119_94582707_0_0_0__203998&from_sustainable_property_sr=1&from=searchresults#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426569929.jpg?k=6c3771ff337a34eace1440f42438b306edb4eee743c08952a68117a35003fbcb&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426569931.jpg?k=dcaed39513824ae59b46db4a41a768cf64a4d7a484fb0ff6349e5ad810f823fb&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426570168.jpg?k=4673bfb24bad492785ff56e9200a8d25ac484ccd19bff7b86cadc6fd8de61046&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426569913.jpg?k=9b21555fdef5bef7138f08fff8482a8a39a8727ca916bfa5adc46b0e48d0ef08&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426569815.jpg?k=13ec695bae622f59209253dd9b13e11868fd77fd835e99503ea0d5c6d0b32e52&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/426569940.jpg?k=d67b865405cdc549b24371b15ffab4c79b323b747b0fb480ead56e7967436034&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        name: 'Four Seasons Hotel Austin',
        address: '98 San Jacinto Boulevard, Austin, TX 78701, United States',
        description: 'City View Room - 0.2 miles from Austin Convention Center',
        primaryLink: null,
        price: '',
        originalPrice: '',
        infoLink:
            'https://www.booking.com/hotel/us/ninty-eight-downtown-austin.html?label=gog235jc-1FCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIF4AIB&aid=356980&ucfs=1&arphpl=1&checkin=2023-09-10&checkout=2023-09-14&dest_id=449367&dest_type=hotel&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=19ca57f37d8f0080&srepoch=1692189034&all_sr_blocks=44936709_203732966_3_42_0&highlighted_blocks=44936709_203732966_3_42_0&matching_block_id=44936709_203732966_3_42_0&sr_pri_blocks=44936709_203732966_3_42_0__413511&from_sustainable_property_sr=1&from=searchresults#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/275326116.jpg?k=aedccd0377020b0a4dce375a7b82fa370f80342a26e807dae00951d51f78bcbf&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/121920637.jpg?k=1bd5d20365a7da07ba316c652f5c0db74f4a436b05be45ed614dd3f62d30da26&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/121918580.jpg?k=bb2958cf0904c6b96d5d3af44f7cac8b2074d1d42a61ced66fdd3e78a4d7d491&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/275327560.jpg?k=9dc5a30a3a2dd972f35d72443a824932b279e33db95646a2c78b9b03e88be28c&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/185314948.jpg?k=a9a7db7f0f0ee67ecf4c18f72f8a3385a83c2caf3e8353696387140348de8349&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/275322459.jpg?k=c83d37c8ff03e79693418ec663d8a829f75e2122fcbae73d876018576946f7bb&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/275336274.jpg?k=a20b94ca6265fc6ffa6af84518aa2accc03d468e79e0286469e2fbcbc2d85c2d&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        name: 'Hilton Austin',
        address: '500 E 4th Street, Austin, TX 78701',
        description: '0.1 miles from Austin Convention Center',
        primaryLink: null,
        price: '',
        originalPrice: '',
        infoLink:
            'https://www.booking.com/hotel/us/hilton-austin.html?label=gog235jc-1FCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIF4AIB&aid=356980&ucfs=1&arphpl=1&checkin=2023-09-10&checkout=2023-09-14&dest_id=41951&dest_type=hotel&group_adults=2&req_adults=2&no_rooms=1&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=b012582a987e02be&srepoch=1692189142&all_sr_blocks=4195104_94258758_2_2_0&highlighted_blocks=4195104_94258758_2_2_0&matching_block_id=4195104_94258758_2_2_0&sr_pri_blocks=4195104_94258758_2_2_0__176142&from_sustainable_property_sr=1&from=searchresults#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/484386432.jpg?k=ee23a71291ed373fcfc9b33066145ab24f44413f602c46a7d4f790fdf895b6a9&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/484386488.jpg?k=cd15f97174ace5e9a8127bdd43f3ab40f7febce1d29641fca6a5ac33e5ea26d4&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/484386596.jpg?k=e302ebf3f69901970ddaa88aab16396d7e2d9d3a738a41528b8066933e5e6c03&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/484386674.jpg?k=f8a4c05b71e499d7aafe842007e5329b23d976628ab081274f39676cb3f1078e&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/484386656.jpg?k=976bafaaf97dd7a859a3f1230b42b6917c88e730952157fddb53b6977270a072&o=&hp=1',
                dimensions,
            },
        ],
    },
    {
        featured: false,
        name: 'The LINE Austin',
        address: '111 E Cesar Chavez Street, Austin, TX 78701, United States',
        description: 'King/Queen Bed - 0.3 miles from Austin Convention Center',
        primaryLink: null,
        price: '',
        originalPrice: '',
        infoLink:
            'https://www.booking.com/hotel/us/the-line-austin.html?aid=356980&label=gog235jc-1FCAso7AFCE3Zhbi16YW5kdC1hLWtpbXB0b25IM1gDaDuIAQGYATG4AQfIAQzYAQHoAQH4AQKIAgGoAgO4Av3p8qYGwAIB0gIkMTBhMTRiMGMtZWUyZi00MDBjLWIzMjEtMjBmYjUwMzlhM2I02AIF4AIB&all_sr_blocks=352573502_117884467_2_32_0;checkin=2023-09-10;checkout=2023-09-14;dest_id=3525735;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=352573502_117884467_2_32_0;hpos=1;matching_block_id=352573502_117884467_2_32_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=352573502_117884467_2_32_0__203579;srepoch=1692805977;srpvid=63276fab39d3003c;type=total;ucfs=1&#hotelTmpl',
        images: [
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/146446715.jpg?k=cb64564616af8ae4facf3b19e4e734c932e117486d04d6a8f6dd1052baab5b39&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/152340599.jpg?k=519d36a0ee670fdb99b148bdcc04f961dd0282cf46995a6072febd936090823a&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/146208864.jpg?k=f2df8dbbc15e8345b6d8bcaba4f8bed386551d584eb71ad51fec67ddce8e9e16&o=&hp=1',
                dimensions,
            },
            {
                url: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/146208871.jpg?k=bbb1c75cb7f42470eb7bcd3e7f0002b07482a387c445c142025b81fc25470aa4&o=&hp=1',
                dimensions,
            },
        ],
    },
];

export default hotels;
