import { Room } from '@/types/pages/api/events/sessionize';

const getLivestreamUrl = (room: Room): string => {
    // find first livestream url in sessions (all sessions have the same url)
    const currentSession = room?.sessions.find(sess => sess.liveUrl);

    return currentSession?.liveUrl || '';
};

export default getLivestreamUrl;
