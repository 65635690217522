import * as React from 'react';

import type { NumbersItem } from '@/types/events-custom-fields';

type Props = {
    items: NumbersItem[];
};
const EventHomepageNumbers = (props: Props) => {
    const { items } = props;

    return (
        <div className="w-full grid gap-4 lg:gap-8 grid-cols-2 lg:grid-cols-3 md:flex-0 items-start text-left mt-10">
            {items.map(i => (
                <div key={i.text} className="flex flex-col justify-center items-start relative gap-1">
                    <p className="text-4xl lg:text-6xl text-gray-900">{i.number}</p>
                    <p className="text-md md:text-lg uppercase text-gray-500">{i.text}</p>
                </div>
            ))}
        </div>
    );
};

export default EventHomepageNumbers;
