import * as React from 'react';

import { Spinner } from '@blockworks/ui/spinner';
import { cn } from '@blockworks/ui/utils';

type GravityButtonProps = {
    className?: string;
    children?: React.ReactChild;
    disabled?: boolean;
};

const GravityButton: {
    (props: GravityButtonProps): JSX.Element;
    ScrollRef?: React.RefObject<any>;
    OnSubmit?: () => void;
    disabled?: boolean;
} = (props: GravityButtonProps) => {
    const { className, children } = props;

    let text: React.ReactChild = 'Submit';
    if (children) {
        if (typeof children === 'string') {
            text = children;
        }
    }

    const submitButtonClicked = () => {
        if (GravityButton.ScrollRef && GravityButton.ScrollRef.current /* + other conditions */) {
            GravityButton.ScrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }

        if (GravityButton.OnSubmit) {
            GravityButton.OnSubmit();
        }
    };

    return (
        <>
            <button
                type="submit"
                onClick={submitButtonClicked}
                disabled={!!props.disabled}
                className={cn(
                    'relative mt-8 inline-flex justify-center rounded-none border border-transparent bg-indigo-600 py-2 px-10 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                    className,
                )}
            >
                {props.disabled ? <Spinner mx="auto" /> : text}
            </button>
        </>
    );
};

GravityButton.ScrollRef = undefined;
GravityButton.OnSubmit = () => {};

export default GravityButton;
