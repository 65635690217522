import * as React from 'react';
import { Tab } from '@headlessui/react';

import { cn } from '@blockworks/ui/utils';

import PlanByController from '@/components/pages/event/layout/components/agenda/components/plan-by-controller';
import type { TabLayoutProps } from '@/components/pages/event/types/tab-layout';

const AgendaLayout = (props: TabLayoutProps) => {
    const { event, agenda } = props;

    if (!agenda || agenda.length === 0) {
        return (
            <>
                <div className="p-5 md:p-10 xl:px-20 md:pr-32 h-96">
                    Agenda for <span className="font-semibold">{event.title}</span> are not available, yet. Please
                    follow our socials for updates.
                </div>
            </>
        );
    }

    return (
        <div className="p-5 md:p-10 xl:px-20 md:pr-32 pb-96">
            <div className="z-10 grid grid-cols-1 gap-10 py-6 w-full xl:pr-12">
                <Tab.Group>
                    <Tab.List className="flex flex-col md:flex-row md:space-x-10 space-y-2 md:space-y-0 pl-0">
                        {agenda?.map(a => (
                            <Tab
                                key={`${a.title}-tab`}
                                className={({ selected }) =>
                                    cn('text-left text-2xl py-2', selected ? 'border-b-2' : '')
                                }
                            >
                                <h2 className="text-event-accent">{a.title}</h2>
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-0">
                        {agenda?.map(a => {
                            if (!a.date) {
                                console.warn(
                                    `No date defined for ${a.title} in the events agenda. Define a date in order for the agenda to show up.`,
                                );
                            }
                            return (
                                <Tab.Panel key={a.title} className={cn('rounded-xl bg-white', '')}>
                                    <>
                                        {!!a.date && (
                                            <div className="-m-1 ">
                                                <PlanByController date={a.date} tracks={a.tracks || []} />
                                            </div>
                                        )}
                                    </>
                                </Tab.Panel>
                            );
                        })}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    );
};

export default AgendaLayout;
